import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { hot } from 'react-hot-loader';
import Icon from '@wtag/rcl-icon';
import { EmphasisTag, I18nText, SidePanel } from '@wtag/react-comp-lib';
import { AccordionItem } from '@wtag/rcl-accordion';
import FlightDetails from 'sharedWebpack/IBE/SearchResult/flight/FlightDetails';
import SearchFlightInfo from 'sharedWebpack/IBE/SearchResult/flight/SearchFlightInfo';
import AirlineStandardLogos from 'sharedWebpack/IBE/SearchResult/flight/AirlineStandardLogos';
import SeatCountIconWithLabel from 'sharedWebpack/IBE/SearchResult/flight/SeatCountIconWithLabel';
import AncillaryServices from 'sharedWebpack/IBE/Cart/Items/flight/AncillaryServices';
import FlightSeatMap from 'sharedWebpack/IBE/Cart/Items/flight/SeatDetails/FlightSeatMapContainer';
import getServices from 'sharedWebpack/IBE/Cart/helpers/getServices';
import { BAGGAGE_ICON } from 'sharedWebpack/ProductIcon/productIconNames';
import getSegmentServicesByTripWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getSegmentServicesByTripWithQuantities';
import getTripServicesWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getTripServicesWithQuantities';
import getItemServicesWithQuantities from 'sharedWebpack/IBE/Cart/helpers/getItemServicesWithQuantities';
import getSegmentSeatsBySeatId from 'sharedWebpack/IBE/Cart/helpers/getSegmentSeatsBySeatId';
import TripDetailsHeader from '../TripDetailsHeader';
import ServiceItemTag from '../ServiceItemTag';
import SeatTags from './SeatTags';
import './styles.scss';
import PopoverItems from './PopoverItems';

const getUniqueFlightServicesItems = flightServices => {
  if (!Array.isArray(flightServices)) {
    return null;
  }

  const flightServicesMapItems = {};
  flightServices.forEach(({ id, description }) => {
    flightServicesMapItems[id] = description;
  });

  return Object.values(flightServicesMapItems);
};

const UniqueBaggagePiecesText = ({ uniqueBaggagePiecesTextList, flightServicesItems }) => {
  const isAncillaryServiceAvailable =
    uniqueBaggagePiecesTextList.length || flightServicesItems.length;

  return isAncillaryServiceAvailable > 0 ? (
    uniqueBaggagePiecesTextList.map(text => (
      <EmphasisTag
        className="seat-tag"
        text={
          <Icon
            name={BAGGAGE_ICON}
            className="seat-tag__icon"
            size="normal"
            color="success"
            showLabel={true}
            iconLabel={text}
          />
        }
        type="success"
        size="tiny"
      />
    ))
  ) : (
    <Icon
      name={BAGGAGE_ICON}
      color="tertiary"
      size="tiny"
      showLabel={true}
      iconLabel={I18n.t('components.ibe.cart_item.flight.ancillary.included')}
    />
  );
};

UniqueBaggagePiecesText.propTypes = {
  uniqueBaggagePiecesTextList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flightServicesItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const UniqueSeatsPiecesText = ({ allSeats, segments }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const totalSeats = allSeats.length;
  const maxNumberOfSeatsToShow = 2;

  const onPopoverClose = () => setIsPopoverOpen(false);
  const hiddenSeatCountText =
    totalSeats > maxNumberOfSeatsToShow ? `+ ${totalSeats - maxNumberOfSeatsToShow}` : '';
  const onPopoverOpen = () => setIsPopoverOpen(true);
  const seatAmountsAndTitles = allSeats.map(({ grossAmount, itemTitle }) => ({
    grossAmount,
    itemTitle,
  }));

  const seatPopoverContent = (
    <Fragment>
      <h4 className="tags-popover__title">
        {I18n.t('components.ibe.cart_item.flight.seats.popup_title', {
          count: totalSeats,
        })}
      </h4>
      <SeatTags segments={segments} seatAmountsAndTitles={seatAmountsAndTitles} />
    </Fragment>
  );

  const hiddenSeats = hiddenSeatCountText && (
    <span
      role="button"
      className="flight-item__number-of-visible-tags"
      tabIndex={0}
      onClick={onPopoverOpen}
      onKeyDown={onPopoverClose}
    >
      {hiddenSeatCountText}
    </span>
  );

  return (
    <div className="d-flex flex-column-gap-8 flight-services__seats">
      <SeatTags
        seatCount={maxNumberOfSeatsToShow}
        segments={segments}
        seatAmountsAndTitles={seatAmountsAndTitles}
      />

      <PopoverItems
        isVisible={isPopoverOpen}
        content={seatPopoverContent}
        onOutsideClick={onPopoverClose}
        hiddenElements={hiddenSeats}
      />
    </div>
  );
};

UniqueSeatsPiecesText.propTypes = {
  allSeats: PropTypes.arrayOf(
    PropTypes.shape({
      grossAmount: PropTypes.string,
      itemTitle: PropTypes.string,
    }),
  ).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      originCode: PropTypes.string,
      destinationCode: PropTypes.string,
      seats: PropTypes.arrayOf(
        PropTypes.shape({
          row: PropTypes.string,
          seat: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

const Trip = ({
  item,
  flightServicesByResultId,
  tripList,
  trip,
  flightServices,
  showFlightSeatsStatus,
  totalTravelerCount,
  payLater,
  shouldLockScroll,
  setShouldLockScroll,
  showMinimalInfo,
  laymanMode,
  isPostBooking,
}) => {
  const { subItems, bookingAttributes, id: itemId } = item || {};

  const [activeAccordionKey, setActiveAccordionKey] = useState(false);

  const handleAccordionClick = () => {
    setActiveAccordionKey(prev => !prev);
  };

  const [isAncillariesPopoverOpen, setIsAncillariesPopoverOpen] = useState(false);

  const {
    id,
    originCode,
    startsAtInUserTz,
    endsAtInUserTz,
    originTerminal,
    segments,
    stopovers,
    durationInMinutes,
    destinationCode,
    destinationTerminal,
    availableSeatCount,
    cabinClass: cabinClasses,
    bookingClasses,
    overnight,
    fareType: fareTypes,
  } = trip;
  const [sidePanelTitle, setSidePanelTitle] = useState(null);
  const [sidePanelBody, setSidePanelBody] = useState(null);
  const [openSidePanel, setOpenSidePanel] = useState(false);

  const hideSidePanel = () => {
    setOpenSidePanel(false);
  };

  const bookingClassesText = bookingClasses.join(', ');
  const tripAirlineLogos = segments.map(({ marketingAirlineInfo }) => marketingAirlineInfo);
  const tripAirlineCarriers = tripAirlineLogos.map(({ code }) => code);
  const marketingAirlineCodes = segments.map(({ operatingFlight, marketingCarrierCode }) => ({
    code: operatingFlight,
    logo: marketingCarrierCode,
  }));

  const allSeatsIDsBySegment = segments.flatMap(({ seats }) =>
    seats.map(({ id: seatId }) => seatId),
  );
  const services = flightServicesByResultId && flightServicesByResultId[bookingAttributes.resultId];
  const seatMaps = services && services.seatMaps;
  const allSeats = getSegmentSeatsBySeatId(subItems, allSeatsIDsBySegment);
  const availableFlightServices = services && getServices(services);

  const tripSegmentServices = getSegmentServicesByTripWithQuantities(
    availableFlightServices,
    subItems,
    originCode,
    destinationCode,
  );

  const wholeTripServices = getTripServicesWithQuantities(
    availableFlightServices,
    subItems,
    originCode,
    destinationCode,
  );

  const flightItemServices = getItemServicesWithQuantities(availableFlightServices, subItems);

  const allItemServices = [...tripSegmentServices, ...wholeTripServices, ...flightItemServices];
  const totalServices = allItemServices.length;

  const isSeatServiceAvailable =
    seatMaps &&
    seatMaps.length > 0 &&
    seatMaps.filter(seatmap => seatmap.sections.length > 0).length > 0;

  const baggagesPiecesListFromSegments = segments
    .filter(({ baggagePiecesIncluded: { count, kgPerPiece } }) => count && kgPerPiece)
    .map(({ baggagePiecesIncluded: { count, kgPerPiece } }) =>
      I18n.t(
        'activerecord.attributes.journey_element/flight_segment.baggage_pieces_included_count_with_weight',
        { count, unit_weight: kgPerPiece },
      ),
    );

  const uniqueBaggagePiecesTextList = baggagesPiecesListFromSegments.filter(
    (text, index) => baggagesPiecesListFromSegments.indexOf(text) === index,
  );

  const flightServicesItems = getUniqueFlightServicesItems(flightServices);
  const eTicketsPresent = segments.some(segment => segment.tickets.length > 0);
  const hiddenAncillariesCountText = totalServices > 1 ? `+ ${totalServices - 1}` : '';

  const onAncillariesPopoverClose = () => setIsAncillariesPopoverOpen(false);
  const onAncillariesPopoverOpen = () => setIsAncillariesPopoverOpen(true);

  const ancillariesPopoverContent = (
    <Fragment>
      <h4 className="tags-popover__title">
        {I18n.t('components.ibe.cart_item.flight.ancillary.popup_title', {
          count: totalServices + 1,
        })}
      </h4>

      <UniqueBaggagePiecesText
        uniqueBaggagePiecesTextList={uniqueBaggagePiecesTextList}
        flightServicesItems={flightServicesItems}
      />

      {allItemServices.map(({ id: itemServiceId, description }) => (
        <ServiceItemTag key={itemServiceId} label={description} />
      ))}
    </Fragment>
  );

  const hiddenAncillaries = hiddenAncillariesCountText && (
    <span
      role="button"
      className="flight-item__number-of-visible-tags"
      tabIndex={0}
      onClick={onAncillariesPopoverOpen}
      onKeyDown={onAncillariesPopoverClose}
    >
      {hiddenAncillariesCountText}
    </span>
  );

  return (
    <AccordionItem
      className={classNames('w-100', {
        'flight-item-accordion--open': activeAccordionKey,
      })}
      id={id}
      header={<TripDetailsHeader airlineLogos={tripAirlineLogos} tripDetails={trip} />}
      isActive={activeAccordionKey}
      onClick={handleAccordionClick}
    >
      <div className="d-flex justify-between flight-logos-seats__container">
        <div className="col-lg-7 col-12 col-bleed col flight-logos-qr-codes">
          <AirlineStandardLogos
            className="flight__airline-summary"
            carriers={tripAirlineCarriers}
            codes={marketingAirlineCodes}
          />
        </div>
        {showFlightSeatsStatus && (
          <div className="d-flex justify-center">
            <SeatCountIconWithLabel
              className="justify-end"
              seatCount={availableSeatCount}
              travellerCount={totalTravelerCount}
            />
          </div>
        )}
      </div>
      <FlightDetails
        group={{
          destinationTerminal,
          arrivalTime: endsAtInUserTz,
          destinationCode,
          tripDurationInMinutes: durationInMinutes,
          id,
          stopovers,
          segments,
          originTerminal,
          departureTime: startsAtInUserTz,
          originCode,
        }}
        setSidePanelTitle={setSidePanelTitle}
        setSidePanelBody={setSidePanelBody}
        setOpenSidePanel={setOpenSidePanel}
      />
      <SidePanel
        className="deal-item__side-panel"
        title={sidePanelTitle}
        body={sidePanelBody}
        isOpened={openSidePanel}
        onClick={hideSidePanel}
      />
      <SearchFlightInfo
        group={{
          resultId: id,
          cabinClasses,
          bookingClasses: bookingClassesText,
          overnight,
          fareTypes,
          payLater,
        }}
        laymanMode={laymanMode}
        showMinimalInfo={showMinimalInfo}
        eTicketsPresent={eTicketsPresent}
        tripId={id}
        tripSegments={segments}
      />
      {!isPostBooking && Object.keys(item).length > 0 && (
        <div className="d-flex-col flight-services__actions">
          <div className="d-flex justify-between w-100 flight-services__actions__content">
            {allSeats.length > 0 ? (
              <UniqueSeatsPiecesText
                allSeats={allSeats}
                originCode={originCode}
                destinationCode={destinationCode}
                segments={segments}
              />
            ) : (
              <Icon
                name="seat"
                color={isSeatServiceAvailable ? 'tertiary' : 'default'}
                size="tiny"
                showLabel={true}
                iconLabel={
                  isSeatServiceAvailable ? (
                    <I18nText
                      id="flight_seat_map.seat_reservation_status.available"
                      returnStringOnly={true}
                    />
                  ) : (
                    <I18nText
                      id="flight_seat_map.seat_reservation_status.not_available"
                      returnStringOnly={true}
                    />
                  )
                }
              />
            )}
            <FlightSeatMap
              trips={tripList}
              seatMaps={services && services.seatMaps}
              resultId={bookingAttributes && bookingAttributes.resultId}
              itemId={itemId}
              shouldLockScroll={shouldLockScroll}
            />
          </div>
          <div className="d-flex justify-between w-100 flight-services__actions__content">
            <span className="d-flex align-center flight-tags wrap flight-services__actions__content">
              {(availableFlightServices && availableFlightServices.length > 0) ||
              (flightServicesItems && flightServicesItems.length > 0) ? (
                <UniqueBaggagePiecesText
                  uniqueBaggagePiecesTextList={uniqueBaggagePiecesTextList}
                  flightServicesItems={flightServicesItems}
                />
              ) : (
                <Icon
                  name={BAGGAGE_ICON}
                  color="default"
                  size="tiny"
                  showLabel={true}
                  iconLabel={I18n.t('components.ibe.cart_item.flight.ancillary.not_available')}
                />
              )}
              {allItemServices.slice(0, 1).map(({ id: itemServiceId, description }) => (
                <ServiceItemTag key={itemServiceId} label={description} />
              ))}

              <PopoverItems
                isVisible={isAncillariesPopoverOpen}
                content={ancillariesPopoverContent}
                onOutsideClick={onAncillariesPopoverClose}
                hiddenElements={hiddenAncillaries}
              />
            </span>
            <AncillaryServices
              services={services}
              item={item}
              trips={tripList}
              setShouldLockScroll={setShouldLockScroll}
            />
          </div>
        </div>
      )}
    </AccordionItem>
  );
};

Trip.defaultProps = { laymanMode: false, showMinimalInfo: false, item: {}, isPostBooking: false };

Trip.propTypes = {
  item: PropTypes.shape({
    subItems: PropTypes.arrayOf(PropTypes.shape({})),
    bookingAttributes: PropTypes.shape({ resultId: PropTypes.string }),
    id: PropTypes.number,
  }),
  flightServicesByResultId: PropTypes.shape().isRequired,
  tripList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  trip: PropTypes.shape().isRequired,
  flightServices: PropTypes.shape().isRequired,
  showFlightSeatsStatus: PropTypes.bool.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  payLater: PropTypes.bool.isRequired,
  shouldLockScroll: PropTypes.bool.isRequired,
  setShouldLockScroll: PropTypes.func.isRequired,
  laymanMode: PropTypes.bool,
  showMinimalInfo: PropTypes.bool,
  isPostBooking: PropTypes.bool,
};

export default hot(module)(Trip);
