import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@wtag/rcl-card';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { connect } from 'react-redux';
import { SidePanel, I18nText, Spinner } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import Alert from '@wtag/rcl-alert';
import Button from '@wtag/rcl-button';
import LazyLoad, { forceCheck as lazyLoadForceCheck } from 'react-lazyload';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import { STEP_CHOOSE_NEXT_TRIP, STEP_GO_TO_MATRIX_SELECTION } from '../../actions/flight';
import { STATS, FILTER_PARAMS } from '../commonProps';
import history from '../../lib/history';
import GroupedFlightStandard from './GroupedFlightStandard';
import EmptyFilterAndResult from './SearchLoadingScreen/EmptyFilterAndResult';
import FilterComponent from './Filter';
import ResultTabs from './ResultTabs';
import { stringifyQueryString } from '../../helpers/qsMethods';
import GroupedFlightCompact from './GroupedFlightCompact';
import { TYPE_STANDARD } from '../../../helpers/viewType';
import './style.scss';

class ResultsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openSidePanel: false,
      sidePanelTitle: null,
      sidePanelBody: null,
      numberOfResultsToShow: 10,
      numberOfMatchedResultsToShow: 10,
      showDeselectAllConfirmationModal: false,
      isLargeScreen: window.innerWidth > 991,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    lazyLoadForceCheck();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onOpenConfirmationModal = () => {
    this.setState({ showDeselectAllConfirmationModal: true });
  };

  onShowMoreOtherResults = () => {
    this.setState({ numberOfResultsToShow: this.state.numberOfResultsToShow + 10 });
  };

  onShowMoreMatchedResults = () => {
    this.setState({ numberOfMatchedResultsToShow: this.state.numberOfMatchedResultsToShow + 10 });
  };

  setTopOfListNode = node => {
    if (node) {
      this.topOfListNode = node;
    }
  };

  setSidePanelBody = body => {
    this.setState({ sidePanelBody: body });
  };

  setSidePanelTitle = title => {
    this.setState({ sidePanelTitle: title });
  };

  setOpenSidePanel = () => {
    this.setState({ openSidePanel: true });
  };

  handleResize = () => {
    this.setState({
      isLargeScreen: window.innerWidth > 991,
    });
  };

  hideSidePanel = () => {
    this.setState({ openSidePanel: false });
  };

  tabChange = value => {
    history.push(`/flights/searches/${this.props.match.params.searchId}/${value}`);
  };

  nextTab = () => {
    const { searchId } = this.props.match.params;
    const { selectedFlightGroups } = this.props;
    this.props.trips.every((value, index) => {
      if (!selectedFlightGroups[index + 1]) {
        history.push(`/flights/searches/${searchId}/${index + 1}`);
        return false;
      }
      return true;
    });
  };

  goToTopOfList = () => {
    scrollIntoViewIfNeeded(this.topOfListNode, false, { duration: 150 });
    setTimeout(lazyLoadForceCheck, 160);
  };

  resultGroup = ({
    resultGroup,
    selectedTripMap,
    selectedTrip,
    deselectGroup,
    disableCard,
    selectCallback,
  }) => {
    const { price, options, marketingAirlineLogos } = resultGroup;
    const isItemSelected = selectedTripMap[selectedTrip] === resultGroup.id;
    const disableItem =
      selectedTripMap[selectedTrip] !== '' && resultGroup.id !== selectedTripMap[selectedTrip];
    const filteredFareBrands = options && options.filter(option => option.fareBrands !== 'none');
    const lowestFareBrand = filteredFareBrands && filteredFareBrands.shift();
    const totalFilteredFareBrands = Array.isArray(filteredFareBrands)
      ? filteredFareBrands.length
      : 0;
    const lowestFareBrandTitle = !!lowestFareBrand && lowestFareBrand.fareBrands;
    const marketingAirlineQCodes =
      resultGroup.marketingAirlineCodes &&
      resultGroup.marketingAirlineCodes.map((code, index) => ({
        code,
        logo: marketingAirlineLogos && marketingAirlineLogos[index],
      }));

    return (
      <LazyLoad
        key={resultGroup.id}
        height="100"
        offset={100}
        placeholder={
          <tbody>
            <tr>
              <td style={{ height: 100 }} />
            </tr>
          </tbody>
        }
      >
        {this.props.viewMode === TYPE_STANDARD ? (
          <GroupedFlightStandard
            isItemSelected={isItemSelected}
            group={resultGroup}
            selectCallback={option => {
              this.goToTopOfList();
              selectCallback(option);
            }}
            onDeselect={deselectGroup}
            setSidePanelBody={this.setSidePanelBody}
            setSidePanelTitle={this.setSidePanelTitle}
            setOpenSidePanel={this.setOpenSidePanel}
            disableSelect={this.props.searchInProgress}
            disableCard={disableCard}
            totalTravelerCount={this.props.totalTravelerCount}
            laymanMode={this.context.laymanMode}
            people={this.props.people}
            marketingAirlineQCodes={marketingAirlineQCodes}
            lowestFareBrandTitle={lowestFareBrandTitle}
            price={price}
            totalFilteredFareBrands={totalFilteredFareBrands}
            filteredFareBrands={filteredFareBrands}
            disableItem={disableItem}
          />
        ) : (
          <GroupedFlightCompact
            group={resultGroup}
            selectCallback={option => {
              this.goToTopOfList();
              selectCallback(option);
            }}
            onDeselect={deselectGroup}
            setSidePanelBody={this.setSidePanelBody}
            setSidePanelTitle={this.setSidePanelTitle}
            setOpenSidePanel={this.setOpenSidePanel}
            disableSelect={this.props.searchInProgress}
            totalTravelerCount={this.props.totalTravelerCount}
            laymanMode={this.context.laymanMode}
            people={this.props.people}
            totalFilteredFareBrands={totalFilteredFareBrands + 1}
            marketingAirlineQCodes={marketingAirlineQCodes}
            lowestFareBrandTitle={lowestFareBrandTitle}
            price={price}
            isItemSelected={isItemSelected}
            disableItem={disableItem}
            disableCard={disableCard}
            lowestFareBrand={lowestFareBrand && lowestFareBrand.price.grossLegPrice}
          />
        )}
      </LazyLoad>
    );
  };

  selectGroup = group => {
    this.props.selectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  resetSelectGroup = group => {
    this.props.resetSelectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  refreshResult = () => {
    const { searchId, tripId } = this.props.match.params;

    this.props.fetchResults(searchId, tripId);
  };

  closeConfirmationModal = () => {
    this.setState({ showDeselectAllConfirmationModal: false });
  };

  deselectGroup = group => {
    this.props.deselectGroup(group);
    this.refreshResult();
  };

  deselectAllGroups = () => {
    const { searchId, tripId } = this.props.match.params;

    this.closeConfirmationModal();
    this.props.deselectAllGroups();

    if (parseInt(tripId, 10) !== 1) {
      history.push(`/flights/searches/${searchId}/1`);
    } else {
      this.refreshResult();
    }
  };

  doNextStep = nextStep => {
    if (!nextStep) return;

    const selectedFlightGroups = {};

    switch (nextStep.step) {
      case STEP_GO_TO_MATRIX_SELECTION:
        Object.keys(nextStep.selectedFlightGroups).forEach(flightGroupKey => {
          selectedFlightGroups[flightGroupKey] = nextStep.selectedFlightGroups[flightGroupKey].id;
        });
        history.push(
          `/flights/searches/${this.props.match.params.searchId}/matrix${stringifyQueryString(
            {
              flightGroups: selectedFlightGroups,
            },
            { arrayFormat: 'repeat' },
          )}`,
        );
        break;

      case STEP_CHOOSE_NEXT_TRIP:
        this.nextTab();
        break;

      default:
        throw new Error(`Unknown nextStep '${nextStep}'`);
    }
  };

  selectGroup = group => {
    this.props.selectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  resetSelectGroup = group => {
    this.props.resetSelectGroup(group).then(nextStep => this.doNextStep(nextStep));
  };

  populateResults = (
    numberOfResultsToShow,
    resultGroups,
    disableFlightGroup,
    selectedFlightGroupsIdMap,
    selectedTrip,
    selectedResults,
    renderedResultGroup,
    selectGroup,
    deselectGroup,
    otherMatchedResults,
  ) => {
    for (let iteration = 0; iteration < numberOfResultsToShow; iteration += 1) {
      const resultGroup = resultGroups.matches[iteration];
      if (resultGroup && disableFlightGroup(resultGroup, selectedFlightGroupsIdMap, selectedTrip)) {
        selectedResults.push(
          renderedResultGroup({
            resultGroup,
            selectCallback: selectGroup,
            selectedTripMap: selectedFlightGroupsIdMap,
            selectedTrip,
            deselectGroup,
          }),
        );
      } else if (resultGroup) {
        otherMatchedResults.push(
          renderedResultGroup({
            resultGroup,
            selectCallback: selectGroup,
            selectedTripMap: selectedFlightGroupsIdMap,
            selectedTrip,
            deselectGroup,
          }),
        );
      }
    }
  };

  filterExcludeMatchingTrips = (selectedResults, otherMatchedResults, otherResults) => {
    if (
      selectedResults.length === 0 &&
      otherMatchedResults.length === 0 &&
      otherResults.length === 0
    ) {
      return I18n.t('components.ibe.results.filter_exclude_matching_trips');
    }

    return null;
  };

  render() {
    const { selectedFlightGroups } = this.props;

    let content;
    if (this.props.searchCompleted && !this.props.searchYieldedResults) {
      content = (
        <Card
          version="v2"
          size="full"
          color="tertiary"
          emptyCardImageSrc={<Icon name="plane" rotate="45" />}
          emptyCardText={I18n.t('components.ibe.results.nothing_found')}
        />
      );
    } else if (
      this.props.searchCompleted &&
      !this.props.hasResultsToRender &&
      this.props.searchYieldedResults
    ) {
      content = (
        <Card
          className="filter-no-result"
          version="v2"
          size="full"
          color="tertiary"
          emptyCardImageSrc={<Icon name="plane" rotate="45" />}
          emptyCardText={I18n.t('components.ibe.results.filter_no_options_found')}
        />
      );
    } else if (this.props.hasResultsToRender) {
      const tabs = [];
      const selectedFlightGroupIds = [];
      const selectedFlightGroupsIdMap = {};
      const selectedTrip = parseInt(this.props.match.params.tripId, 10);

      this.props.trips.forEach((item, index) => {
        selectedFlightGroupsIdMap[index + 1] = '';
      });

      Object.keys(selectedFlightGroups).forEach(value => {
        if (selectedFlightGroups[value].id) {
          selectedFlightGroupIds.push(selectedFlightGroups[value].id);
          selectedFlightGroupsIdMap[value] = selectedFlightGroups[value].id;
        }
      });

      const disableFlightGroup = resultGroup => {
        const selectedFilter =
          selectedFlightGroupsIdMap[selectedTrip] !== '' &&
          resultGroup.id === selectedFlightGroupsIdMap[selectedTrip];

        return selectedFilter;
      };

      this.props.trips.forEach((trip, tripCount) => {
        const selectedResults = [];
        const otherMatchedResults = [];

        for (let i = 0; i < this.state.numberOfResultsToShow; i += 1) {
          const resultGroup = this.props.resultGroups.matches[i];
          if (resultGroup && disableFlightGroup(resultGroup)) {
            selectedResults.push(
              this.resultGroup({
                resultGroup,
                selectCallback: this.selectGroup,
                selectedTripMap: selectedFlightGroupsIdMap,
                selectedTrip,
                deselectGroup: this.deselectGroup,
                viewMode: this.props.viewMode,
              }),
            );
          } else if (resultGroup) {
            otherMatchedResults.push(
              this.resultGroup({
                resultGroup,
                selectCallback: this.selectGroup,
                selectedTripMap: selectedFlightGroupsIdMap,
                selectedTrip,
                deselectGroup: this.deselectGroup,
                viewMode: this.props.viewMode,
              }),
            );
          }
        }

        const otherResults = this.props.resultGroups.others
          .slice(0, this.state.numberOfResultsToShow)
          .map(resultGroup =>
            this.resultGroup({
              resultGroup,
              selectCallback: this.resetSelectGroup,
              selectedTripMap: selectedFlightGroupsIdMap,
              selectedTrip,
              deselectGroup: this.deselectGroup,
              disableCard: true,
              viewMode: this.props.viewMode,
            }),
          );

        let otherResultsElements = null;
        const alertContent = (
          <div className="deselect-container">
            <span className="deselect-container__alert-message">
              {I18n.t('components.ibe.results.flight_deselect_alert_message')}
            </span>
            <Button
              version="v2"
              className="deselect-container__button results-table__title-other-alert-button"
              size="small"
              type="normal"
              disabled={this.props.searchInProgress || selectedFlightGroupIds.length === 0}
              label={I18n.t('components.ibe.results.deselect_all')}
              onClick={this.openConfirmationModal}
            />
          </div>
        );

        if (otherResults.length) {
          otherResultsElements = (
            <div className="other">
              <div className="results-table">
                <div className="results-table__title-other">
                  <I18nText id="ibe.results.other_flights" returnStringOnly={true} />
                </div>
                <Alert
                  className="results-table__title-other-alert"
                  isVisible={!!alertContent}
                  type="warning"
                  hideClose={true}
                >
                  {alertContent}
                </Alert>
                {otherResults}
              </div>
            </div>
          );
        }

        tabs.push({
          title: (
            <div className="pill-tabs__title">
              <span>
                {this.state.isLargeScreen
                  ? I18n.t('components.ibe.results.trip_title', {
                      count: `${tripCount + 1}`,
                      origin: trip.origin,
                      destination: trip.destination,
                    })
                  : I18n.t('components.ibe.results.only_trip_title', {
                      origin: trip.origin,
                      destination: trip.destination,
                    })}
              </span>
              <span
                className={classNames('icon-holder', {
                  'icon-holder--selected': selectedFlightGroups[tripCount + 1],
                })}
              >
                {selectedFlightGroups[tripCount + 1] && <Icon name="check" size="tiny" />}
              </span>
            </div>
          ),
          getContent: () => (
            <Fragment>
              {this.props.searchInProgress && (
                <div className="flight__spinner">
                  <Alert type="warning" hideClose={true} isVisible={true} isIconVisible={false}>
                    <div className="progress-spinner">
                      {I18n.t('components.ibe.results.loader_message')}
                      <div className="progress-spinner__circular">
                        <Spinner size="tiny" bgColor="neutral" />
                      </div>
                    </div>
                  </Alert>
                </div>
              )}

              <div className="results-table">
                <div className="results-table__title">
                  {selectedFlightGroups[tripCount + 1] ? (
                    <I18nText id="ibe.results.flight_selected" />
                  ) : (
                    <I18nText id="ibe.results.flight_select" />
                  )}
                  <span>{` ${trip.origin} - ${trip.destination}`}</span>
                </div>

                {selectedResults}
              </div>

              {selectedResults.length === 0 &&
                otherMatchedResults.length === 0 &&
                otherResults.length === 0 &&
                I18n.t('components.ibe.results.filter_exclude_matching_trips')}

              {otherMatchedResults}
              {otherResultsElements}
              {this.props.resultGroups.matches.length + this.props.resultGroups.others.length >
                this.state.numberOfResultsToShow && (
                <div className="col-12 col-bleed">
                  <div
                    role="presentation"
                    className="col-grid direction-row col-bleed align-center justify-center car-result-card__show-more"
                    onClick={this.onShowMoreClick}
                  >
                    <I18nText id="ibe.search_results.car.label.load_more" returnStringOnly={true} />
                  </div>
                </div>
              )}
            </Fragment>
          ),
        });
      });

      content = (
        <div className="results__flight-tabs-container flight-results__fade-in">
          {/* for pill tabs key is array item index. that's why we are decreasing the value by one to match item index i.e selectedTabKey={parseInt(this.props.match.params.tripId, 10) - 1} */}
          <ResultTabs
            tripId={this.props.match.params.tripId}
            trips={this.props.trips}
            resultGroups={this.props.resultGroups}
            searchInProgress={this.props.searchInProgress}
            selectedFlightGroups={selectedFlightGroups}
            numberOfResultsToShow={this.state.numberOfResultsToShow}
            numberOfMatchedResultsToShow={this.state.numberOfMatchedResultsToShow}
            renderedResultGroup={this.resultGroup}
            selectGroup={this.selectGroup}
            deselectGroup={this.deselectGroup}
            populateResults={this.populateResults}
            resetSelectGroup={this.resetSelectGroup}
            onShowMoreMatchedResults={this.onShowMoreMatchedResults}
            onShowMoreOtherResults={this.onShowMoreOtherResults}
            onOpenConfirmationModal={this.onOpenConfirmationModal}
            filterExcludeMatchingTrips={this.filterExcludeMatchingTrips}
            tabChange={this.tabChange}
          />
        </div>
      );
    }

    const resultReadyToDisplay =
      this.props.searchCompleted && this.props.searchYieldedResults && this.props.tripLoaded;
    const tripsAvailable = this.props.searchCompleted && this.props.numberOfAvailableTrips > 0;
    const hasNoResultForCurrentTrip =
      tripsAvailable && !this.props.hasResultsToRender && this.props.tripLoaded;
    const hasResultForCurrentTrip = tripsAvailable && this.props.hasResultsToRender;
    const NoResultForAnyTrips =
      ((this.props.searchCompleted && !this.props.searchYieldedResults) ||
        (this.props.searchCompleted &&
          !this.props.hasResultsToRender &&
          this.props.searchYieldedResults)) &&
      this.props.tripLoaded;

    const filterComponent = (
      <FilterComponent
        filterFlightSearch={this.props.filterFlightSearch}
        resetFilters={this.props.resetFilters}
        resetFiltersByKey={this.props.resetFiltersByKey}
        fetchAirlineInformations={this.props.fetchAirlineInformations}
        filterParams={this.props.filterParams}
        stats={this.props.stats}
        trips={this.props.trips}
        currency={this.props.currency}
        airlineInformations={this.props.airlineInformations}
        appliedFilters={this.props.appliedFilter}
        match={this.props.match}
      />
    );

    let partialResult;
    const showPartialResults = () => {
      if (hasResultForCurrentTrip) {
        partialResult = (
          <div className="flight-results__container">
            <div className="col-xlg-4 col-lg-4 col-12 col-bleed flight-results__filters">
              {filterComponent}
            </div>

            <div className="col-xlg-8 col-lg-8 col-12 col-bleed">
              <div className="results__results" ref={this.setTopOfListNode}>
                <div className="results__flight-tabs-container">
                  <ResultTabs
                    tripId={this.props.match.params.tripId}
                    trips={this.props.trips}
                    resultGroups={this.props.resultGroups}
                    searchInProgress={this.props.searchInProgress}
                    selectedFlightGroups={selectedFlightGroups}
                    numberOfResultsToShow={this.state.numberOfResultsToShow}
                    numberOfMatchedResultsToShow={this.state.numberOfMatchedResultsToShow}
                    renderedResultGroup={this.resultGroup}
                    selectGroup={this.selectGroup}
                    deselectGroup={this.deselectGroup}
                    populateResults={this.populateResults}
                    resetSelectGroup={this.resetSelectGroup}
                    onShowMoreMatchedResults={this.onShowMoreMatchedResults}
                    onShowMoreOtherResults={this.onShowMoreOtherResults}
                    onOpenConfirmationModal={this.onOpenConfirmationModal}
                    filterExcludeMatchingTrips={this.filterExcludeMatchingTrips}
                    tabChange={this.tabChange}
                  />
                  <SidePanel
                    title={this.state.sidePanelTitle}
                    body={this.state.sidePanelBody}
                    isOpened={this.state.openSidePanel}
                    onClick={() => this.hideSidePanel()}
                  />
                </div>
              </div>
            </div>
          </div>
        );
        return partialResult;
      } else if (hasNoResultForCurrentTrip) {
        partialResult = (
          <div className="flight-results__container">
            <div className="col-xlg-4 col-lg-4 col-12 col-bleed flight-results__filters">
              {filterComponent}
            </div>

            <div className="col-xlg-8 col-lg-8 col-12 col-bleed">
              <div className="results__results" ref={this.setTopOfListNode}>
                <div className="results__flight-tabs-container">
                  <ResultTabs
                    tripId={this.props.match.params.tripId}
                    trips={this.props.trips}
                    selectedFlightGroups={selectedFlightGroups}
                    tabChange={this.tabChange}
                    isResultAvailable={false}
                  />
                  <Card
                    version="v2"
                    size="full"
                    color="tertiary"
                    emptyCardImageSrc={<Icon name="plane" rotate="45" />}
                    emptyCardText={I18n.t('components.ibe.results.filter_no_options_found')}
                  />
                </div>
              </div>
            </div>
          </div>
        );
        return partialResult;
      } else if (NoResultForAnyTrips) {
        return content;
      }

      return <EmptyFilterAndResult />;
    };

    return (
      <div className="results">
        {this.state.showDeselectAllConfirmationModal && (
          <ConfirmationModal
            confirmationHeader={I18n.t('components.ibe.results.deselect_confirm')}
            subHeader={I18n.t('components.ibe.results.deselect_warning')}
            confirmationText={I18n.t('components.ibe.results.deselect_yes')}
            rejectionText={I18n.t('components.ibe.results.deselect_no')}
            onConfirm={this.deselectAllGroups}
            onReject={this.closeConfirmationModal}
            withAction={true}
            type="warning"
            isModalOpen={this.state.showDeselectAllConfirmationModal}
          />
        )}
        {this.props && resultReadyToDisplay && (
          <div className="flight-results__container">
            {this.props.isShowFilters && (
              <div className="col-xlg-4 col-bleed flight-results__filters">
                <FilterComponent
                  filterFlightSearch={this.props.filterFlightSearch}
                  resetFilters={this.props.resetFilters}
                  resetFiltersByKey={this.props.resetFiltersByKey}
                  fetchAirlineInformations={this.props.fetchAirlineInformations}
                  filterParams={this.props.filterParams}
                  stats={this.props.stats}
                  trips={this.props.trips}
                  currency={this.props.currency}
                  airlineInformations={this.props.airlineInformations}
                  appliedFilters={this.props.appliedFilter}
                  match={this.props.match}
                />
              </div>
            )}
            <div className="col-xlg-8 flight-results__container__results col-12 col-bleed">
              <div className="results__results" ref={this.setTopOfListNode}>
                {content}
                <SidePanel
                  title={this.state.sidePanelTitle}
                  body={this.state.sidePanelBody}
                  isOpened={this.state.openSidePanel}
                  onClick={() => this.hideSidePanel()}
                />
              </div>
            </div>
          </div>
        )}
        {this.props && !resultReadyToDisplay && showPartialResults()}
      </div>
    );
  }
}

ResultsComponent.contextTypes = {
  laymanMode: PropTypes.bool.isRequired,
};

ResultsComponent.defaultProps = {
  searchCompleted: false,
  tripLoaded: false,
  searchYieldedResults: false,
  hasResultsToRender: false,
  resultGroups: null,
  stats: null,
  filterParams: null,
};

ResultsComponent.propTypes = {
  selectGroup: PropTypes.func.isRequired,
  resetSelectGroup: PropTypes.func.isRequired,
  selectedFlightGroups: PropTypes.shape({}).isRequired,
  resetFiltersByKey: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  resultGroups: PropTypes.shape({
    matches: PropTypes.shape([]),
    others: PropTypes.shape([]),
  }),
  searchCompleted: PropTypes.bool,
  tripLoaded: PropTypes.bool,
  deselectGroup: PropTypes.func.isRequired,
  deselectAllGroups: PropTypes.func.isRequired,
  fetchResults: PropTypes.func.isRequired,
  filterFlightSearch: PropTypes.func.isRequired,
  fetchAirlineInformations: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  airlineInformations: PropTypes.shape().isRequired,
  people: PropTypes.shape({
    adults: PropTypes.arrayOf(PropTypes.shape({})),
    children: PropTypes.arrayOf(PropTypes.shape({})),
    infants: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      origin: PropTypes.string,
      destination: PropTypes.string,
      date: PropTypes.string,
    }),
  ).isRequired,
  searchYieldedResults: PropTypes.bool,
  hasResultsToRender: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      searchId: PropTypes.string,
      tripId: PropTypes.string,
    }),
  }).isRequired,
  searchInProgress: PropTypes.bool.isRequired,
  totalTravelerCount: PropTypes.number.isRequired,
  stats: PropTypes.shape(STATS),
  filterParams: PropTypes.shape(FILTER_PARAMS),
  appliedFilter: PropTypes.shape({}).isRequired,
  viewMode: PropTypes.string.isRequired,
  isShowFilters: PropTypes.bool.isRequired,
  numberOfAvailableTrips: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  selectedFlightGroups: state.flights.selectedFlightGroups,
});

export default connect(mapStateToProps)(ResultsComponent);
