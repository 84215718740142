import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import HotelSummaryDetails from '../../../HotelSummaryDetails';
import CartItemLabels from '../CartItemLabels';
import ItemPricing from '../ItemPricing';
import './styles.scss';

const HotelCartItemHeader = ({
  isCartLocked,
  item,
  itemNo,
  journeyElement,
  removeFromCart,
  itemDetails,
  status,
}) => {
  const { journeyElementType, available, pendingConfirmation, type } = item;
  const { street, city, countryCode, stars } = journeyElement || {};

  return (
    <Fragment>
      <div className="d-flex justify-between flex-gap-8 hotel-cart-item-header__label">
        <CartItemLabels
          isCartLocked={isCartLocked}
          item={item}
          itemNo={itemNo}
          journeyElement={journeyElement}
          removeFromCart={removeFromCart}
          itemDetails={itemDetails}
        />

        <div className="item__wrapper-hotel-price hotel-cart-item-header-sm">
          <ItemPricing
            type={journeyElementType || type}
            available={available}
            item={item}
            journeyElement={journeyElement}
            itemStatus={status}
            pendingConfirmation={pendingConfirmation}
          />
        </div>
      </div>

      <div className="hotel-cart-item-header__separator hotel-cart-item-header-sm" />

      <div className="d-flex justify-between align-end item__wrapper-hotel-summary">
        <HotelSummaryDetails
          street={street}
          city={city}
          countryCode={countryCode}
          stars={stars}
          item={item}
          journeyElement={journeyElement}
          itemStatus={status}
          itemDetails={itemDetails}
        />
      </div>
    </Fragment>
  );
};

HotelCartItemHeader.propTypes = {
  isCartLocked: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    type: PropTypes.string,
    journeyElementType: PropTypes.string,
    available: PropTypes.bool,
    pendingConfirmation: PropTypes.bool.isRequired,
  }).isRequired,
  itemNo: PropTypes.number.isRequired,
  journeyElement: PropTypes.shape({
    durationInDays: PropTypes.number,
    rooms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  removeFromCart: PropTypes.func.isRequired,
  itemDetails: PropTypes.shape({}).isRequired,
  status: PropTypes.shape({}).isRequired,
};

export default HotelCartItemHeader;
