/* eslint-disable react/prop-types */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'throttle-debounce/debounce';
import Card from '@wtag/rcl-card';
import Button from '@wtag/rcl-button';
import { I18nText, CheckBox } from '@wtag/react-comp-lib';
import Alert from '@wtag/rcl-alert';

import feeList from '../lib/feeList';
import CartItemWrapper from '../Cart/Items/CartItemWrapper';
import Progress from '../components/Progress';
import Summary from '../Cart/Summary';
import CheckoutProgress from '../components/CheckoutProgress';

import history from '../lib/history';
import CostCenters from '../CostCenters';

import LaymanBookButton from './BookButtons/LaymanBookButton';
import AgentBookButton from './BookButtons/AgentBookButton';
import LegalPapers from './LegalPapers';
import SharePanel from './ShareSideBar/SharePanel';
import BookingProgressBar from './BookingProgressBar';
import { parseQueryString } from '../helpers/qsMethods';
import {
  CART_STATUS_OPEN,
  CART_STATUS_BOOKED,
  CART_STATUS_PAID,
  CART_STATUS_LOCKED,
} from '../actions/common';
import { getViewportName, DESKTOP_DEVICE } from '../../old/libraries/responsiveHelpers';
import './styles.scss';

class ReviewBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndConditionsRequired: null,
      privacyPolicyRequired: null,
      bookButtonDisabled: false,
      legalPapers: null,
      airlineTermsAndConditions: null,
      airlineTermsAndConditionsRequired: null,
      legalPapersFetched: false,
      legalPapersModalOpen: false,
      isBookingReceived: false,
      isBookingButtonClicked: false,
      checkingAvailabilityInLaymanMode: false,
      isLargeScreen: false,
    };
    this.locationQuery = parseQueryString(this.props.location.search);
    this.orderSummaryRef = createRef();
  }

  componentDidMount() {
    if (this.props.cartId && this.props.travelers.length > 0) this.fetchLegalPapers();

    if (!this.props.fees.length) {
      this.props.fetchFees(this.context.callbackParams);
    }

    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.locationQuery = parseQueryString(this.props.location.search);
    }

    if (
      this.context.iframeMode &&
      this.props.bookingCompleted &&
      prevProps.bookingCompleted !== this.props.bookingCompleted
    ) {
      window.parent.postMessage({ status: 'bookingCompleted' }, '*');
    }

    if (this.props.paymentFailureReason) {
      this.redirectToCart(this.props.paymentFailureReason);
    }

    if (this.locationQuery.paymentGateway && this.locationQuery.redirectToCart) {
      if (this.locationQuery.error) {
        this.redirectToCart(this.locationQuery.error);
      } else {
        this.redirectToCart();
      }
    }

    if (this.props.cartId !== prevProps.cartId && this.props.travelers.length > 0) {
      this.fetchLegalPapers();
    }

    if (this.props.flightServicesNeeded !== prevProps.flightServicesNeeded) {
      if (this.props.flightServicesNeeded && !this.props.flightServicesFetched) {
        this.props.fetchServices(this.props.cart, this.context.callbackParams);
      }
    }

    if (this.props.cartId) {
      this.props.cartItems.forEach(cartItem => {
        const conditionToRedirect =
          cartItem.formOfPayment &&
          cartItem.formOfPayment.type === 'credit_card' &&
          this.props.cardDataAvailableByToken[cartItem.formOfPayment.cardDetails.token] !== true &&
          cartItem.formOfPayment.cardDetails.storedInCcv !== true;
        if (conditionToRedirect) {
          this.redirectToCart('unsaved_card');
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onLegalPapersModalOpen = value => {
    this.setState({ legalPapersModalOpen: value });
  };

  setIsBookingButtonClicked = inputState => {
    this.setState({ isBookingButtonClicked: inputState });
  };

  setCheckingAvailabilityInLaymanMode = inputState => {
    this.setState({ checkingAvailabilityInLaymanMode: inputState });
  };

  setLegalPaperAcceptance = event => {
    if (!this.state.legalPapersModalOpen) {
      const checked = event.target.checked;
      const papers = [];

      if (this.state.termsAndConditionsRequired) papers.push('termsAndConditions');
      if (this.state.privacyPolicyRequired) papers.push('privacyPolicy');
      if (this.state.airlineTermsAndConditionsRequired) papers.push('airlineTermsAndConditions');
      this.props
        .setLegalPaperAcceptance(papers, checked)
        .then(() => this.props.fetchCart(this.props.cartId, this.context.callbackParams));
    }
  };

  shouldRenderPreviousButton = () => {
    if (this.context.laymanMode) {
      return !this.state.isBookingButtonClicked && !this.state.checkingAvailabilityInLaymanMode;
    }

    return !this.state.isBookingButtonClicked;
  };

  shouldDisablePreviousButton = () => {
    if (this.context.laymanMode) {
      return this.state.isBookingButtonClicked || this.state.checkingAvailabilityInLaymanMode;
    }

    return this.state.isBookingButtonClicked;
  };

  redirectToCart = (reason = null) => {
    this.props.resetPayment();
    if (reason) {
      history.push({ pathname: `/carts/${this.props.cartId}`, search: `?paymentError=${reason}` });
    } else {
      history.push(`/carts/${this.props.params.id}`);
    }
  };

  makeBookButtonDisabled = () => {
    this.setState({ bookButtonDisabled: true });
  };

  bookWithDebounce = debounce(10000, true, options => this.props.book(options));

  fetchLegalPapers = () => {
    this.props.fetchLegalPapers(this.context.laymanMode).then(data => {
      const termsAndConditions = data.states.termsAndConditions;
      const privacyPolicy = data.states.privacyPolicy;
      const airlineTermsAndConditions = data.states.airlineTermsAndConditions;
      const legalPapers = data.legalPapers;
      this.setState({
        termsAndConditionsRequired: termsAndConditions && termsAndConditions.required,
        privacyPolicyRequired: privacyPolicy && privacyPolicy.required,
        airlineTermsAndConditionsRequired:
          airlineTermsAndConditions && airlineTermsAndConditions.required,
        legalPapers,
        airlineTermsAndConditions: data.airlineTermsAndConditions,
        legalPapersFetched: true,
      });
    });
  };

  allLegalPapersAccepted = () => {
    const acceptances = this.props.legalPaperAcceptances;
    if (this.state.termsAndConditionsRequired && !acceptances.termsAndConditions) return false;
    if (this.state.privacyPolicyRequired && !acceptances.privacyPolicy) return false;
    if (this.state.airlineTermsAndConditionsRequired && !acceptances.airlineTermsAndConditions)
      return false;
    return true;
  };

  prepareForBooking = (callbackParams, procedure) => {
    this.makeBookButtonDisabled();
    return Promise.all([this.props.storeCartRecipientData()]).then(() => {
      this.setState({ isBookingReceived: true });
      return this.props.createOrder(callbackParams, procedure);
    });
  };

  loadingBookingServices = () =>
    !this.props.flightServicesFetched || !this.state.legalPapersFetched;

  bookingButtonDisabled = () =>
    !this.props.bookingPossible ||
    this.state.bookButtonDisabled ||
    !this.props.allItemsAvailable ||
    this.props.isBooking ||
    (this.props.cartState === 'booked' && this.props.redirectURL === null) ||
    !this.allLegalPapersAccepted();

  checkCartAvailability = () =>
    this.props.fetchCartAllItemsAvailability(this.context.callbackParams);

  removeAllTheSubItems = subItemIds =>
    Promise.all(
      subItemIds.map(subItemId =>
        this.props.removeSubItem(subItemId, { layman: this.context.laymanMode }, false),
      ),
    ).then(() => this.props.fetchCart(this.props.cartId, this.context.callbackParams));

  handleResize = () => {
    const viewPortName = getViewportName();
    this.setState({ isLargeScreen: viewPortName === DESKTOP_DEVICE });
  };
  render() {
    const OPEN = 'open';
    const isBookingCompleted = this.props.bookingCompleted || this.props.cartState === 'booked';
    const isBookingPendingByThirdParty =
      this.locationQuery.paymentGateway === 'true' && !isBookingCompleted;

    const disablePreviousButton =
      this.props.bookingFailed ||
      this.props.bookingCompleted ||
      this.props.cartState !== 'open' ||
      this.locationQuery.paymentGateway === 'true' ||
      this.shouldDisablePreviousButton();

    const fromSharedCarts = this.locationQuery.fromSharedCarts;

    const emailConfirmation =
      this.props.cartState === OPEN &&
      ((this.context.confirmationMailForTravelApp && this.context.laymanMode) ||
        (!this.context.laymanMode && this.context.confirmationMailForAgent));

    let text;

    if (this.props.isBooking || this.props.itemsBookingInProgress || isBookingPendingByThirdParty) {
      text = <BookingProgressBar isBookingPendingByThirdParty={isBookingPendingByThirdParty} />;
    }

    if ((this.props.bookingCompleted || this.props.cartState === 'booked') && !fromSharedCarts) {
      let successText = (
        <I18nText id="ibe.bookings.not_confirmed.text_html" returnStringOnly={true} />
      );
      if (this.props.bookingQuoted) {
        successText = (
          <I18nText id="ibe.bookings.not_confirmed.text_html" returnStringOnly={true} />
        );
      }
      if (this.props.allItemsBookingConfirmed) {
        successText = <I18nText id="ibe.bookings.done.text_html" returnStringOnly={true} />;
      }
      if (this.context.travelArranger.enabled) {
        successText = (
          <I18nText id="ibe.booking.done.text_as_travel_arranger" returnStringOnly={true} />
        );
      }
      text = (
        <Progress
          title={<I18nText id="ibe.booking.done.title" returnStringOnly={true} />}
          completed={true}
        >
          {successText}
        </Progress>
      );
    }

    let openOrderLink;

    const redirectURL =
      this.props.redirectURL || (this.props.cartStatus && this.props.cartStatus.orderUrl);

    if (redirectURL && (this.context.travelArranger.allowContinueToOrder || !!fromSharedCarts)) {
      openOrderLink = (
        <Button
          className="booking__order-link"
          version="v2"
          size="small"
          label={
            <I18nText
              id="ibe.check_out_progress.buttons.continue_to_order"
              returnStringOnly={true}
            />
          }
          primary={true}
          onClick={() => {
            window.location.assign(redirectURL);
          }}
          disabled={!!this.props.isBooking}
        />
      );
    }

    let bookingReceivedText;
    if (this.props.isBooking) {
      bookingReceivedText = (
        <div className="booking__received-text">
          <I18nText
            id="ibe.check_out_progress.texts.booking_received_text"
            returnStringOnly={true}
          />
        </div>
      );
    }

    let bookButton;
    if (
      !this.props.bookingFailed &&
      !this.props.bookingCompleted &&
      !this.props.itemsBookingInProgress &&
      !fromSharedCarts
    ) {
      if (this.context.laymanMode) {
        if (this.props.cartState && this.props.cartState !== 'paid') {
          bookButton = (
            <LaymanBookButton
              isBookingButtonClicked={this.state.isBookingButtonClicked}
              setIsBookingButtonClicked={this.setIsBookingButtonClicked}
              checkingAvailability={this.state.checkingAvailabilityInLaymanMode}
              setCheckingAvailability={this.setCheckingAvailabilityInLaymanMode}
              cartId={this.props.match.params.id}
              cartItems={this.props.cartItems}
              retrackBooking={this.props.retrackBooking}
              paymentGateway={this.props.paymentGateway}
              onlyOneItemFromSearch={this.props.onlyOneItemFromSearch}
              bookingPossible={this.props.bookingPossible}
              checkCartAvailability={this.checkCartAvailability}
              canProceedWithPayment={this.props.canProceedWithPayment}
              startBooking={options => this.bookWithDebounce(options)}
              prepareForBooking={procedure =>
                this.prepareForBooking(this.context.callbackParams, procedure)
              }
              prepareForExternalPayment={procedure =>
                this.props.prepareForExternalPayment(this.context.callbackParams, procedure)
              }
              disabled={this.bookingButtonDisabled()}
              fetchPaymentGatewayInfo={this.props.fetchPaymentGatewayInfo}
              uniqueCCTokens={this.props.uniqueCCTokens}
              currency={this.props.currency}
              storeCartRecipientData={this.props.storeCartRecipientData}
              showQuoteButton={!this.props.canBookQuoteItems && !isBookingPendingByThirdParty}
              loadingBookingServices={this.loadingBookingServices()}
              bookingComponentRefByToken={this.props.bookingComponentRefByToken}
              ccTokensThatNeedToBook={this.props.ccTokensThatNeedToBook}
              fetchJourneyElement={this.props.fetchJourneyElement}
              removeItems={this.removeAllTheSubItems}
            />
          );
        }
      } else {
        bookButton = (
          <AgentBookButton
            isBookingButtonClicked={this.state.isBookingButtonClicked}
            setIsBookingButtonClicked={this.setIsBookingButtonClicked}
            startBooking={options => this.bookWithDebounce(options)}
            disabled={this.bookingButtonDisabled()}
            cartId={this.props.cartId}
            cartItems={this.props.cartItems}
            currency={this.props.currency}
            onlyOneItemFromSearch={this.props.onlyOneItemFromSearch}
            bookingPossible={this.props.bookingPossible}
            checkCartAvailability={this.checkCartAvailability}
            uniqueCCTokens={this.props.uniqueCCTokens}
            createOptionPossible={this.props.createOptionPossible}
            prepareForBooking={procedure =>
              this.prepareForBooking(this.context.callbackParams, procedure)
            }
            bookingComponentRefByToken={this.props.bookingComponentRefByToken}
            ccTokensThatNeedToBook={this.props.ccTokensThatNeedToBook}
            payLaterItemsToBeQuoted={this.props.payLaterItemsToBeQuoted}
            disableBooking={this.props.disableBooking}
            fetchJourneyElement={this.props.fetchJourneyElement}
            removeItems={this.removeAllTheSubItems}
            resetFormOfPayment={this.props.resetFormOfPayment}
            showQuoteButton={!this.props.canBookQuoteItems && !isBookingPendingByThirdParty}
          />
        );
      }
    }

    const isCartBooked =
      this.props.cartState === CART_STATUS_BOOKED || this.props.cartState === CART_STATUS_PAID;

    const isCartReadOnly =
      this.props.cartState === CART_STATUS_BOOKED || this.props.cartState === CART_STATUS_LOCKED;

    const currentNextButton = bookButton || openOrderLink;

    return (
      <div>
        <CheckoutProgress
          cartId={this.props.match.params.id}
          disableMoveBackward={disablePreviousButton}
          currentStep="booking"
          bookingRequestReceived={this.state.isBookingReceived || !!fromSharedCarts}
          nextButton={currentNextButton}
          totalCartItems={this.props.totalCartItems}
          orderSummaryRef={this.orderSummaryRef}
        />
        <div className="container book-button-actions--mobile">{currentNextButton}</div>
        <Alert
          className="container booking__alert"
          type="success-light"
          hideClose={true}
          isIconVisible={false}
          isVisible={this.context.laymanMode && this.props.cart.isShared && isCartBooked}
        >
          {I18n.t('cart.cart_share.layman_mode.cart_booked_alert')}
        </Alert>
        <div className="container container--sm-full-width booking">
          <div className="grid booking__card-container">
            <div className="col-12 col-grid direction-row review-cart-item wrap">
              <div className="col-lg-8 col-grid col-bleed">
                {this.props.bookingFailed && (
                  <div className="col-grid col-bleed review-cart-item-alert">
                    <Alert hideClose={true} type="danger" isVisible={!!this.props.bookingError}>
                      {this.props.bookingError}
                    </Alert>
                  </div>
                )}
                {!isCartReadOnly &&
                  this.state.legalPapersFetched &&
                  (this.state.legalPapers.length > 0 ||
                    this.state.airlineTermsAndConditions.length > 0) && (
                    <div
                      className={classNames('review-cart-item__legal-papers', {
                        'review-cart-item__legal-papers--not-layman': !this.context.laymanMode,
                        'review-cart-item__legal-papers--layman': this.context.laymanMode,
                      })}
                    >
                      <Alert
                        hideClose={true}
                        type={this.allLegalPapersAccepted() ? 'success' : 'warning'}
                        isVisible={true}
                        isIconVisible={false}
                      >
                        <CheckBox
                          size="small"
                          name="check-box"
                          label={
                            <LegalPapers
                              legalPapers={this.state.legalPapers}
                              airlineTermsAndConditions={this.state.airlineTermsAndConditions}
                              onLegalPapersModalOpen={this.onLegalPapersModalOpen}
                              legalPapersModalOpen={this.state.legalPapersModalOpen}
                            />
                          }
                          checked={this.allLegalPapersAccepted()}
                          onChange={event => this.setLegalPaperAcceptance(event)}
                        />
                      </Alert>
                    </div>
                  )}
                <Alert
                  className="container booking__alert"
                  type="success-light"
                  hideClose={true}
                  isIconVisible={false}
                  isVisible={this.props.isBooking && !!bookingReceivedText}
                >
                  {bookingReceivedText}
                </Alert>
                {text}
                {this.props.cartItems.length > 0 &&
                  this.props.cartItems.map((cartItem, index) => (
                    <div>
                      <CartItemWrapper
                        item={cartItem}
                        itemNo={index + 1}
                        cartStatus={this.props.cartStatus}
                        journeyElement={cartItem.journeyElement}
                        showFlightSeatsStatus={true}
                        flightResultDetailsById={this.props.flightResultDetailsById}
                        flightServicesByResultId={this.props.flightServicesByResultId}
                        showServices={false}
                        removeFromCart={this.props.removeFromCart}
                        travelerCount={this.props.travelerCount}
                        staticProducts={this.props.staticProducts}
                        setStaticProductQuantity={this.props.setQuantity}
                        fareBasisInfo={this.props.fareBasisInfo}
                        setOutOfPolicyJustification={this.props.setOutOfPolicyJustification}
                        isLargeScreen={this.state.isLargeScreen}
                        travelers={this.props.travelers}
                      />
                    </div>
                  ))}
                <div className="review-cart-item__no-add-on">
                  <Card
                    version="v2"
                    title={<I18nText id="ibe.cart_buttons.header" returnStringOnly={true} />}
                  >
                    <p>
                      <I18nText id="ibe.cart_buttons.explanation" returnStringOnly={true} />
                    </p>
                  </Card>
                </div>
              </div>
              <div ref={this.orderSummaryRef} className="col-lg-4 col-grid col-bleed cart-sidebar">
                <Card className="order-summary" version="v2">
                  <Summary
                    items={this.props.cartItems}
                    staticProducts={this.props.staticProducts}
                    total={this.props.total}
                    taxes={this.props.taxes}
                    voucher={this.props.voucher}
                    travelerCount={this.props.travelerCount}
                    fees={feeList(
                      this.props.fees,
                      this.props.cartFees,
                      this.props.intl,
                      !this.context.laymanMode,
                      I18n.t('components.ibe.summary.additional_taxes_services_and_charges'),
                    )}
                    currency={this.props.currency}
                    paymentMethodSurcharge={this.props.paymentMethodSurcharge}
                    grossTotalsByGroup={this.props.grossTotalsByGroup}
                    isFetchingCart={this.props.cartState !== CART_STATUS_OPEN && !fromSharedCarts}
                    isFetchingFop={this.props.isFetchingFop}
                    totalWithoutVoucherReduction={this.props.totalWithoutVoucherReduction}
                  />
                </Card>
                {emailConfirmation && (
                  <Card version="v2" shadow="dark">
                    <div className="booking__email">
                      <SharePanel />
                    </div>
                  </Card>
                )}
                {this.props.cartCostCenters.length > 0 && (
                  <div className="cost-centers--small">
                    <CostCenters
                      costCenters={this.props.costCenters}
                      usedCostCenters={this.props.cartCostCenters}
                      tenantCurrency={this.props.currency}
                      allocationPerAccountingType={this.props.allocationPerAccountingType}
                      reviewBookingPage={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReviewBooking.defaultProps = {
  cartState: null,
  redirectURL: null,
  travelers: [],
  cartCostCenters: [],
  allItemsAvailable: false,
  itemsBookingInProgress: false,
  allItemsBookingConfirmed: false,
  bookingQuoted: false,
  location: {
    query: {
      error: null,
    },
  },
  allocationPerAccountingType: [],
  disableBooking: false,
};

ReviewBooking.propTypes = {
  isBooking: PropTypes.bool.isRequired,
  canBookQuoteItems: PropTypes.bool.isRequired,
  bookingPossible: PropTypes.bool.isRequired,
  onlyOneItemFromSearch: PropTypes.bool.isRequired,
  flightServicesFetched: PropTypes.bool.isRequired,
  canProceedWithPayment: PropTypes.bool.isRequired,
  bookingFailed: PropTypes.bool.isRequired,
  bookingError: PropTypes.string.isRequired,
  bookingCompleted: PropTypes.bool.isRequired,
  cartState: PropTypes.oneOf(['open', 'paid', 'booked']),
  cartStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirectURL: PropTypes.string,
  uniqueCCTokens: PropTypes.arrayOf(PropTypes.string).isRequired,
  book: PropTypes.func.isRequired,
  prepareForExternalPayment: PropTypes.func.isRequired,
  params: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  currency: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  storeCartRecipientData: PropTypes.func.isRequired,
  createOptionPossible: PropTypes.bool.isRequired,
  paymentGateway: PropTypes.shape().isRequired,
  flightResultDetailsById: PropTypes.shape().isRequired,
  cartItems: PropTypes.shape([]).isRequired,
  fees: PropTypes.shape([]).isRequired,
  cartFees: PropTypes.shape([]).isRequired,
  voucher: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  travelerCount: PropTypes.shape().isRequired,
  staticProducts: PropTypes.arrayOf(PropTypes.shape).isRequired,
  taxes: PropTypes.string.isRequired,
  travelers: PropTypes.arrayOf(PropTypes.shape),
  cartId: PropTypes.string.isRequired,
  cartCostCenters: PropTypes.arrayOf(PropTypes.shape),
  costCenters: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setLegalPaperAcceptance: PropTypes.func.isRequired,
  fetchCart: PropTypes.func.isRequired,
  fetchFees: PropTypes.func.isRequired,
  legalPaperAcceptances: PropTypes.shape({
    termsAndConditions: PropTypes.bool,
    privacyPolicy: PropTypes.bool,
  }).isRequired,
  setQuantity: PropTypes.func.isRequired,
  itemsBookingInProgress: PropTypes.bool,
  allItemsAvailable: PropTypes.bool,
  createOrder: PropTypes.func.isRequired,
  allItemsBookingConfirmed: PropTypes.bool,
  bookingQuoted: PropTypes.bool,
  fetchPaymentGatewayInfo: PropTypes.func.isRequired,
  fetchCartAllItemsAvailability: PropTypes.func.isRequired,
  fetchLegalPapers: PropTypes.func.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      error: PropTypes.string,
    }),
  }),
  allocationPerAccountingType: PropTypes.arrayOf(PropTypes.shape()),
  setOutOfPolicyJustification: PropTypes.func.isRequired,
  disableBooking: PropTypes.bool,
  payLaterItemsToBeQuoted: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  removeSubItem: PropTypes.func.isRequired,
  resetPayment: PropTypes.func.isRequired,
};

ReviewBooking.contextTypes = {
  travelArranger: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    allowContinueToOrder: PropTypes.bool.isRequired,
  }).isRequired,
  iframeMode: PropTypes.bool.isRequired,
  laymanMode: PropTypes.bool.isRequired,
  callbackParams: PropTypes.shape().isRequired,
  paymentDisabled: PropTypes.bool.isRequired,
  confirmationMailForTravelApp: PropTypes.bool.isRequired,
  confirmationMailForAgent: PropTypes.bool.isRequired,
  fetchJourneyElement: PropTypes.func.isRequired,
};

export default ReviewBooking;
