import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import JourneyIndicator from '../../Indicators/JourneyIndicator';
import EmptyServicePlaceholder from '../EmptyServicePlaceholder';
import Service from '../Service';
import { SUCCESS, DISABLED } from '../../../../../lib/helpers/componentStates';
import './styles.scss';

const SegmentServices = ({
  id,
  originDetails,
  destinationDetails,
  services,
  onChange,
  travelers,
}) => {
  const serviceCount = services.length;
  const { placeName: originPlaceName, countryName: originCountryName } = originDetails;
  const {
    placeName: destinationPlaceName,
    countryName: destinationCountryName,
  } = destinationDetails[0];

  return (
    <div className="segment-services">
      <div className="segment-services__title">
        {I18n.t('components.ibe.flight_service_selection.segment_number', { number: `0${id + 1}` })}
      </div>
      <div className="segment-services__header">
        <div className="segment-services__header--left">
          <JourneyIndicator originDetails={originDetails} destinationDetails={destinationDetails} />
          <div className="segment-services__header--text">
            {I18n.t('components.ibe.flight_service_selection.service_header_text', {
              originPlaceName,
              originCountryName,
              destinationPlaceName,
              destinationCountryName,
            })}
          </div>
        </div>
        <div className="segment-services__header--right">
          <EmphasisTag
            text={I18n.t(
              'components.ibe.flight_service_selection.individual_segment_service_count',
              {
                count: serviceCount,
              },
            )}
            type={serviceCount > 0 ? SUCCESS : DISABLED}
            size="tiny"
          />
        </div>
      </div>
      {serviceCount === 0 ? (
        <EmptyServicePlaceholder
          text={I18n.t('components.ibe.flight_service_selection.individual_segment_service_count', {
            count: serviceCount,
          })}
        />
      ) : (
        <div className="segment-services__content">
          {services.map(service => (
            <Service
              key={service.id}
              onChange={(quantity, price, travelerIndices) =>
                onChange(service.id, quantity, price, travelerIndices)
              }
              travelers={travelers}
              service={service}
            />
          ))}
        </div>
      )}
    </div>
  );
};

SegmentServices.propTypes = {
  id: PropTypes.number.isRequired,
  originDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    countryFlag: PropTypes.string.isRequired,
    placeName: PropTypes.string.isRequired,
  }).isRequired,
  destinationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
      placeName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      currency: PropTypes.string,
      price: PropTypes.string,
    }),
  ).isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SegmentServices;
