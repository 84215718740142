import React from 'react';
import PropTypes from 'prop-types';
import SegmentServices from '../SegmentServices';
import getSegmentServices from '../../../../helpers/getSegmentServices';

const SegmentServiceWrapper = ({ services, segments, airportDetails, onChange, travelers }) =>
  segments.map((segment, index) => {
    const segmentOriginDetails = {
      code: segment.originCode,
      countryFlag: airportDetails[segment.originCode].countryFlag,
      countryName: airportDetails[segment.originCode].countryName,
      placeName: airportDetails[segment.originCode].placeName,
    };

    const segmentDestinationDetails = [
      {
        code: segment.destinationCode,
        countryFlag: airportDetails[segment.destinationCode].countryFlag,
        countryName: airportDetails[segment.destinationCode].countryName,
        placeName: airportDetails[segment.destinationCode].placeName,
      },
    ];

    const segmentServices = getSegmentServices(
      services,
      segment.originCode,
      segment.destinationCode,
    );

    return (
      <SegmentServices
        key={segment.id}
        id={index}
        originDetails={segmentOriginDetails}
        destinationDetails={segmentDestinationDetails}
        services={segmentServices}
        onChange={onChange}
        travelers={travelers}
      />
    );
  });

SegmentServiceWrapper.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({ originCode: PropTypes.string, destinationCode: PropTypes.string }),
  ),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      trip: PropTypes.shape({
        origin: PropTypes.string,
        destination: PropTypes.string,
      }),
    }),
  ).isRequired,
  airportDetails: PropTypes.shape({
    code: PropTypes.string,
    countryName: PropTypes.string,
    countryFlag: PropTypes.string,
  }).isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SegmentServiceWrapper;
