import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import tripTypeIconMapper from 'sharedWebpack/helpers/tripTypeIconMapper';
import { ONEWAY } from 'sharedWebpack/IBE/lib/helpers/tripTypes';
import { countryShape } from '../../admin/Orders/Show/helpers/shapes';
import CountryWithFlag from '../CountryWithFlag';

const JourneySummaryIndicator = ({ tripType, originCountryInfo, destinationCountryInfo }) => {
  const { code: originCode, flag: originFlag, name: originCountryName } = originCountryInfo;
  const {
    code: destinationCode,
    flag: destinationFlag,
    name: destinationCountryName,
  } = destinationCountryInfo;

  return (
    <div className="d-flex align-center column-gap-8">
      <CountryWithFlag code={originCode} flag={originFlag} name={originCountryName} />
      <Icon name={tripTypeIconMapper(tripType)} size="small" />
      <CountryWithFlag
        code={destinationCode}
        flag={destinationFlag}
        name={destinationCountryName}
      />
    </div>
  );
};

JourneySummaryIndicator.defaultProps = {
  tripType: ONEWAY,
};

JourneySummaryIndicator.propTypes = {
  tripType: PropTypes.string,
  originCountryInfo: PropTypes.shape(countryShape).isRequired,
  destinationCountryInfo: PropTypes.shape(countryShape).isRequired,
};

export default JourneySummaryIndicator;
