import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import { connect } from 'react-redux';
import Amount from 'sharedWebpack/Amount';
import SelectedSeatInfo from './SelectedSeatInfo';

const FlightSeatSummary = ({
  segmentMap,
  bookedSeatMap,
  airportMap,
  origin,
  destination,
  travelerCount,
  currency,
}) => {
  const totalTravelerscount = Object.values(travelerCount).reduce(
    (previousValue, currentValue) => previousValue + currentValue.length,
    0,
  );

  const formattedPrice = price =>
    price ? (
      <Amount currency={currency} value={price} />
    ) : (
      <I18nText id="ibe.deals.label.free" returnStringOnly={true} />
    );

  const isFree = givenSeatMap => {
    if (givenSeatMap) {
      return givenSeatMap.price ? givenSeatMap.price === 0 : false;
    }

    return false;
  };

  const bookedSeat = segmentMap[`${origin}-${destination}`].seats.filter(
    item => bookedSeatMap[item.id],
  );

  const travelerMap = () => {
    const bookedSeatIndexMap = {};
    const resultArray = [];
    let i = 0;
    bookedSeat.forEach(item => {
      bookedSeatIndexMap[bookedSeatMap[item.id].travelerIndex] = item;
    });

    while (i < totalTravelerscount) {
      if (bookedSeatIndexMap[i]) {
        const item = bookedSeatIndexMap[i];
        resultArray.push(
          <SelectedSeatInfo
            currency={currency}
            traveler={i + 1}
            selected={true}
            item={item}
            bookedSeatMap={bookedSeatMap}
            isFree={isFree}
            formattedPrice={formattedPrice}
          />,
        );
      } else
        resultArray.push(
          <SelectedSeatInfo
            currency={currency}
            traveler={i + 1}
            selected={false}
            bookedSeatMap={bookedSeatMap}
            isFree={isFree}
            formattedPrice={formattedPrice}
          />,
        );

      i += 1;
    }

    return resultArray;
  };

  return (
    <div className="flight-travelers-seat">
      <span>
        <img
          className="flight-item-trip-airport-info__country-flag"
          src={airportMap[origin].countryFlag}
          alt={airportMap[origin].countryName}
        />
        {origin}
        <div className="loading-information-card__origin-destination__icon">
          <div className="loading-information-card__origin-destination__icon--right">
            <Icon name="arrowUp" rotate="90" />
          </div>
        </div>
        <img
          className="flight-item-trip-airport-info__country-flag"
          src={airportMap[destination].countryFlag}
          alt={airportMap[destination].countryName}
        />
        {destination}
      </span>
      <span className="count">
        {`${totalTravelerscount} ${
          totalTravelerscount === 1
            ? I18n.t('components.ibe.deals.label.traveler')
            : I18n.t('components.ibe.deals.label.travelers')
        }`}
      </span>
      <div className="flight-travelers-seat-detail">{travelerMap()}</div>
    </div>
  );
};

FlightSeatSummary.propTypes = {
  currency: PropTypes.string.isRequired,
  segmentMap: PropTypes.shape({
    seats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        row: PropTypes.string.isRequired,
        seat: PropTypes.string.isRequired,
        segment_id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  bookedSeatMap: PropTypes.shape({
    travelerIndex: PropTypes.number.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  airportMap: PropTypes.instanceOf({
    countryFlag: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
  }).isRequired,
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
  travelerCount: PropTypes.instanceOf({
    adults: PropTypes.arrayOf(
      PropTypes.instanceOf({
        name: PropTypes.string,
      }),
    ),
    children: PropTypes.arrayOf(
      PropTypes.instanceOf({
        name: PropTypes.string,
      }),
    ),
    infant: PropTypes.arrayOf(
      PropTypes.instanceOf({
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

const mapStateToProps = state => ({
  travelerCount: state.common.travelerCount,
});

export default connect(mapStateToProps)(FlightSeatSummary);
