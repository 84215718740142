import React from 'react';
import PropTypes from 'prop-types';
import { CheckBox } from '@wtag/react-comp-lib';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';

const Form = props => {
  const {
    line1,
    setLine1,
    line2,
    setLine2,
    zipCode,
    setZipCode,
    city,
    setCity,
    countryCode,
    setCountryCode,
    type,
    setType,
    primary,
    setPrimary,
    countryOptions,
    addressTypesOptions,
    error,
  } = props;

  const findLabelForSelectBox = (options, value) => {
    const selectedOption = options.filter(option => value === option.value)[0];
    if (selectedOption) {
      return selectedOption.label;
    }

    return null;
  };

  return (
    <div className="col-12 traveller-edit-address-information-form">
      <div className="grid">
        <div className="col-12">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.address.form.placeholder.address_1',
            )}
            label={I18n.t('admin.components.travellers.edit.address.form.label.address_1')}
            touched={true}
            value={line1}
            onChange={value => setLine1(value)}
            required={true}
            error={error.line1}
          />
        </div>
        <div className="col-12">
          <Input
            size="tiny"
            placeholder={I18n.t(
              'admin.components.travellers.edit.address.form.placeholder.address_2',
            )}
            label={I18n.t('admin.components.travellers.edit.address.form.label.address_2')}
            touched={true}
            value={line2}
            onChange={value => setLine2(value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.edit.address.form.placeholder.zip')}
            label={I18n.t('admin.components.travellers.edit.address.form.label.zip')}
            touched={true}
            value={zipCode}
            onChange={value => setZipCode(value)}
            required={true}
            error={error.zipCode}
          />
        </div>
        <div className="col-sm-6">
          <Input
            size="tiny"
            placeholder={I18n.t('admin.components.travellers.edit.address.form.placeholder.city')}
            label={I18n.t('admin.components.travellers.edit.address.form.label.city')}
            touched={true}
            value={city}
            onChange={value => setCity(value)}
            required={true}
            error={error.city}
          />
        </div>
        <div className="col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.address.form.placeholder.country',
            )}
            label={I18n.t('admin.components.travellers.edit.address.form.label.country')}
            width="full"
            options={countryOptions}
            required={true}
            value={
              countryCode !== null
                ? { value: countryCode, label: findLabelForSelectBox(countryOptions, countryCode) }
                : null
            }
            errorMsg={error.countryCode}
            onChange={selectedOption => setCountryCode(selectedOption && selectedOption.value)}
          />
        </div>
        <div className="col-sm-6">
          <SelectBox
            size="tiny"
            placeholderText={I18n.t(
              'admin.components.travellers.edit.address.form.placeholder.type',
            )}
            label={I18n.t('admin.components.travellers.edit.address.form.label.type')}
            width="full"
            options={addressTypesOptions}
            value={
              type !== null
                ? {
                    value: type,
                    label: findLabelForSelectBox(addressTypesOptions, type),
                  }
                : null
            }
            required={true}
            errorMsg={error.addressType}
            onChange={selectedOption => setType(selectedOption && selectedOption.value)}
          />
        </div>
        <div className="col-12 traveller-edit-address-information-form__field">
          <CheckBox
            label={I18n.t('admin.components.travellers.edit.address.form.placeholder.primary')}
            checked={primary}
            onChange={event => setPrimary(event.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {
  line1: PropTypes.string.isRequired,
  setLine1: PropTypes.func.isRequired,
  line2: PropTypes.string.isRequired,
  setLine2: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
  setZipCode: PropTypes.func.isRequired,
  city: PropTypes.string.isRequired,
  setCity: PropTypes.func.isRequired,
  countryCode: PropTypes.string.isRequired,
  setCountryCode: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
  setType: PropTypes.func.isRequired,
  error: PropTypes.shape({
    addressType: PropTypes.string,
    city: PropTypes.string,
    zipCode: PropTypes.string,
    line1: PropTypes.string,
    countryCode: PropTypes.string,
  }).isRequired,
  primary: PropTypes.bool.isRequired,
  setPrimary: PropTypes.func.isRequired,
  addressTypesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

export default Form;
