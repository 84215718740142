import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@wtag/rcl-button';
import Icon from '@wtag/rcl-icon';
import ContentLoaderPlaceholder from '@wtag/rcl-content-loader-placeholder';
import httpClient from 'agentHTTPClient';
import routes from 'agentRoutes';

import Form from './Form';
import AddressCard from './AddressCard';

const sortItems = (x, y) => Number(y.primary) - Number(x.primary);

const AddressesAndLocationsSection = props => {
  const { addresses, fetchTraveller, addressTypes, personId, layman } = props;

  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  const [type, setType] = useState(null);
  const [primary, setPrimary] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);
  const [error, setError] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const hasAddress = addresses && addresses.length > 0;

  let addressesAndLocationsRoutes;

  if (layman) {
    addressesAndLocationsRoutes = {
      create: routes.public.travellerAddresses.create({ person_id: personId }),
    };
  } else {
    addressesAndLocationsRoutes = {
      create: routes.admin.travellerAddresses.create({ person_id: personId }),
    };
  }

  if (Array.isArray(addresses)) {
    addresses.sort(sortItems);
  }

  const addressTypesOptions = Object.keys(addressTypes).map(addressTypeKey => ({
    value: addressTypeKey,
    label: I18n.t(addressTypeKey, {
      scope: 'simple_form.options.person.traveler_addresses.address_type',
    }),
  }));

  const fetchData = () => {
    setIsFetching(true);
    fetchTraveller();
  };

  const fetchCountryOptions = async () => {
    let route = routes.admin.people.countryOptions;
    if (layman) {
      route = routes.public.people.countryOptions;
    }
    const { data } = await httpClient.get(route());
    setCountryOptions(data.countryOptions);
  };

  const resetForm = () => {
    setLine1('');
    setLine2('');
    setZipCode('');
    setCity('');
    setCountryCode(null);
    setType(null);
    setPrimary(false);
    setError({});
  };

  const validateForm = () => {
    const formErrors = {};

    if (!line1) {
      formErrors.line1 = I18n.t('components.ibe.validations.presence');
    }

    if (!zipCode) {
      formErrors.zipCode = I18n.t('components.ibe.validations.presence');
    }

    if (!countryCode) {
      formErrors.countryCode = I18n.t('components.ibe.validations.presence');
    }

    if (!city) {
      formErrors.city = I18n.t('components.ibe.validations.presence');
    }

    if (!countryCode) {
      formErrors.countryCode = I18n.t('components.ibe.validations.presence');
    }

    if (!type) {
      formErrors.addressType = I18n.t('components.ibe.validations.presence');
    }

    setError(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const createAddress = async () => {
    if (!validateForm()) {
      return;
    }

    const travellerParams = {
      traveler_address: {
        address_type: type,
        primary,
        line_1: line1,
        line_2: line2,
        zip_code: zipCode,
        city,
        country_code: countryCode,
      },
    };

    setIsShowForm(false);

    const { data } = await httpClient.post(addressesAndLocationsRoutes.create, {
      ...travellerParams,
    });

    if (data.error === null) {
      fetchData();
      resetForm();
    }
  };

  const onCreate = () => {
    createAddress().catch(({ response }) => {
      if (response.status === 422) {
        setError(response.data.error);
      }
    });
  };

  useEffect(() => {
    resetForm();
  }, [isShowForm]);

  const addressForm = () => (
    <div className="traveller-edit-address-section__form">
      {hasAddress && (
        <div className="traveller-edit-address-section__form-header">
          {I18n.t('admin.components.travellers.edit.address.form.placeholder.add')}
        </div>
      )}
      <Form
        line1={line1}
        setLine1={setLine1}
        line2={line2}
        setLine2={setLine2}
        zipCode={zipCode}
        setZipCode={setZipCode}
        city={city}
        setCity={setCity}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        type={type}
        setType={setType}
        primary={primary}
        setPrimary={setPrimary}
        error={error}
        addressTypesOptions={addressTypesOptions}
        countryOptions={countryOptions}
      />
      <div className="traveller-edit-address-section__form-actions">
        <Button
          label={I18n.t('admin.components.travellers.edit.address.form.label.add')}
          onClick={onCreate}
          version="v2"
          type="primary"
          size="small"
        />

        <Button
          version="v2"
          size="small"
          label={I18n.t('admin.components.shared.action.cancel')}
          type="default"
          onClick={() => setIsShowForm(false)}
        />
      </div>
    </div>
  );

  useEffect(() => {
    fetchCountryOptions();
  }, []);

  useEffect(() => {
    setIsFetching(false);
  }, [addresses]);

  return (
    <div className="traveller-edit-address-section">
      <div className="col-grid col-bleed traveller-edit-address-section__create-form">
        <div className="traveller-edit-address-section__header traveller-edit-address-section__header--with-top-margin">
          {I18n.t('admin.components.travellers.edit.address.header')}

          {addresses && !isShowForm && !isFetching && (
            <Button
              version="v2"
              size="small"
              label={I18n.t('admin.components.organizations.edit.email_and_phone.phone.label.add')}
              type="primary"
              onClick={() => setIsShowForm(true)}
            />
          )}
        </div>

        {isShowForm && !hasAddress && addressForm()}
      </div>
      {addresses && !isFetching ? (
        addresses.map(address => (
          <AddressCard
            address={address}
            personId={personId}
            fetchTraveller={fetchData}
            addressTypesOptions={addressTypesOptions}
            countryOptions={countryOptions}
            layman={layman}
          />
        ))
      ) : (
        <div className="col-grid col-bleed traveller-edit-address-section traveller-edit-address-section__address-card">
          <ContentLoaderPlaceholder numberOfLines={2} isRounded={true} showBackground={false} />
        </div>
      )}

      {isShowForm && hasAddress && (
        <Fragment>
          <div className="col-bleed edit-email-and-phone__mt">
            <hr className="edit-email-and-phone__hr" />
          </div>
          {addressForm()}
        </Fragment>
      )}

      {addresses && addresses.length === 0 && !isFetching && !isShowForm && (
        <div className="col-12 direction-row edit-email-and-phone edit-email-and-phone__placeholder">
          <Icon name="location" size="large" color="tertiary" showBGColor={true} />
          <div className="edit-email-and-phone__explanation edit-email-and-phone__explanation--with-padding">
            {I18n.t('admin.components.organizations.edit.email_and_phone.email.no_address')}
          </div>
        </div>
      )}
    </div>
  );
};

AddressesAndLocationsSection.defaultProps = {
  layman: false,
};

AddressesAndLocationsSection.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      primary: PropTypes.bool,
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      countryCode: PropTypes.string,
      zipCode: PropTypes.string,
    }),
  ).isRequired,
  fetchTraveller: PropTypes.func.isRequired,
  addressTypes: PropTypes.shape({
    billing: PropTypes.number,
    business: PropTypes.number,
    contact: PropTypes.number,
    home: PropTypes.number,
    place_of_work: PropTypes.number,
    recipient: PropTypes.number,
    shipping: PropTypes.number,
  }).isRequired,
  personId: PropTypes.number.isRequired,
  layman: PropTypes.bool,
};

export default AddressesAndLocationsSection;
