import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BaseModal } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import Input from '@wtag/rcl-input';
import SelectBox from '@wtag/rcl-select-box';
import TextEditor from 'sharedWebpack/TextEditor';
import httpClient from 'agentHTTPClient';
import { hot } from 'react-hot-loader';
import parseQuillHtml from 'sharedWebpack/parseQuillHtml';
import ConfirmationModal from 'sharedWebpack/ConfirmationModal';
import './save-send-pdf.scss';

const SaveAndSendPDF = ({
  sendPdfUrl,
  ownerEmail,
  pdfAttributesAsString,
  disabled,
  buttonLabel,
}) => {
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [content, setContent] = useState('');
  const [emails, setEmails] = useState([]);
  const [emailSubject, setEmailSubject] = useState(I18n.t('notifications.order.mail_subject'));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [confirmationAttributes, setConfirmationAttributes] = useState({
    header: null,
    subHeader: null,
  });

  const ownerEmailOption = [{ label: ownerEmail, value: ownerEmail }];

  const onModalClose = () => {
    setSendModalOpen(false);
    setEmails(ownerEmailOption);
    setContent('');
    setEmailSubject(I18n.t('notifications.order.mail_subject'));
  };

  const onConfirmationModalClose = () => {
    setShowConfirmation(false);
    if (confirmationAttributes.type === 'danger') {
      setSendModalOpen(true);
    } else {
      onModalClose();
    }
  };

  const emailAttributes = {
    body: parseQuillHtml(content),
    subject: emailSubject,
    emails: emails.map(email => email.value),
  };

  const showConfirmationsOrError = data => {
    if (data.error === null) {
      setConfirmationAttributes({
        header: data.message,
        subHeader: '',
        type: 'success',
      });
      onModalClose();
    } else {
      setConfirmationAttributes({
        header: data.error,
        subHeader: '',
        type: 'danger',
      });
    }

    setShowConfirmation(true);
    setSendModalOpen(false);
  };

  const sendMail = async () => {
    setInProgress(true);
    const { data } = await httpClient.post(sendPdfUrl, {
      order: { email_attributes: emailAttributes },
      pdf_attributes: pdfAttributesAsString,
    });

    setInProgress(false);
    showConfirmationsOrError(data);
  };

  const modalHeader = (
    <div>
      <span>{I18n.t('admin.components.customize_pdf.save_and_send_modal.header')}</span>
      <div className="save-send-pdf__subheader">
        {I18n.t('admin.components.customize_pdf.save_and_send_modal.subheader')}
      </div>
    </div>
  );

  useEffect(() => {
    setEmails(ownerEmailOption);
  }, [ownerEmail]);

  return (
    <Fragment>
      <BaseModal
        open={sendModalOpen}
        onModalOpen={() => setSendModalOpen(true)}
        onModalClose={onModalClose}
        size="small"
        header={modalHeader}
        disabled={disabled}
        linkText={
          <Button
            label={buttonLabel}
            version="v2"
            size="small"
            type="primary"
            onClick={() => setSendModalOpen(true)}
            disabled={disabled}
          />
        }
      >
        <div>
          <SelectBox
            label={I18n.t('admin.components.email_addresses.label.email_address')}
            placeholderText={I18n.t(
              'admin.components.customize_pdf.save_and_send_modal.email_address_placeholder',
            )}
            size="small"
            className="col-grid col-bleed-x"
            isMulti={true}
            isCreatable={true}
            isClearable={false}
            value={emails}
            onChange={selectedOptions => setEmails(selectedOptions || [])}
            onCreateOption={createdOption =>
              setEmails(prevState => [...prevState, { label: createdOption, value: createdOption }])
            }
            touched={true}
            width="full"
          />
            <Input
              label={I18n.t('admin.components.email_addresses.label.email_subject')}
              placeholder={I18n.t(
                'admin.components.customize_pdf.save_and_send_modal.email_subject_placeholder',
              )}
              size="small"
              className="col-grid col-bleed-x"
              required={true}
              value={emailSubject}
              error={emailSubject.length > 255 && I18n.t('admin.components.email_addresses.errors.error_on_max_length_exceed') }
              touched={true}
              onChange={value => setEmailSubject(value)}
            />
          <div className="col-grid col-bleed-x">
            <TextEditor
              label={I18n.t('admin.components.attachments.description')}
              placeholder={I18n.t(
                'admin.components.customize_pdf.save_and_send_modal.texteditor_placeholder',
              )}
              onChange={setContent}
              value={content}
              isRequired={false}
            />
          </div>

          <div className="col-grid direction-row col-bleed">
            <div className="save-send-pdf__send">
              <Button
                label={I18n.t('admin.shared.action.send')}
                size="small"
                version="v2"
                type="primary"
                disabled={!emails.length || !emailSubject.length || inProgress || emailSubject.length > 255}
                onClick={sendMail}
              />
            </div>
            <Button
              label={I18n.t('admin.shared.action.cancel')}
              size="small"
              version="v2"
              onClick={onModalClose}
            />
          </div>
        </div>
      </BaseModal>

      <div className="save-send-pdf__confirmation-wrapper">
        <ConfirmationModal
          confirmationHeader={confirmationAttributes.header}
          subHeader={confirmationAttributes.subHeader}
          type={confirmationAttributes.type}
          isModalOpen={showConfirmation}
          isConfirmationOnly={true}
          confirmationText={I18n.t('admin.shared.action.close')}
          onConfirm={onConfirmationModalClose}
        />
      </div>
    </Fragment>
  );
};

SaveAndSendPDF.defaultProps = {
  pdfAttributesAsString: null,
  disabled: false,
};

SaveAndSendPDF.propTypes = {
  ownerEmail: PropTypes.string.isRequired,
  sendPdfUrl: PropTypes.string.isRequired,
  pdfAttributesAsString: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default hot(module)(SaveAndSendPDF);
