import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import Icon from '@wtag/rcl-icon';
import { CheckBox } from '@wtag/react-comp-lib';
import Button from '@wtag/rcl-button';
import PriceLabel from './PriceLabel';
import TripShape from './Shapes/index';

class ConfirmAttributeChanges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      style: 'withNewMargin',
    };
  }

  toggleStyle = () => {
    let newStyle = 'withOldMargin';
    if (this.state.style === 'withOldMargin') {
      newStyle = 'withNewMargin';
    }
    this.setState({ style: newStyle });
  };

  decide = keepOldPrice => {
    const { values } = this.props;

    let grossPrice;
    let netPrice;
    if (keepOldPrice) {
      netPrice = values.before.netPrice;
      grossPrice = values.before.grossPrice;
    } else {
      netPrice = values.after.netPrice;
      grossPrice = values.after[this.state.style].grossPrice;
    }

    this.props.onDecision({
      id: values.id,
      decisions: { gross_price: grossPrice, net_price: netPrice },
    });
  };

  render() {
    const { values, disabled, trips } = this.props;

    const airportsMap = trips
      .flatMap(trip => trip.airports)
      .reduce((acc, airport) => {
        acc[airport.code] = airport;

        return acc;
      }, {});

    const journeyDetail = trips.map(trip => {
      const currentOrigin = airportsMap[trip.originCode];
      const currentDestination = airportsMap[trip.destinationCode];
      return (
        <Fragment key={trip.id}>
          <div className="refresh-booking-flight_info__steps">
            {trip.airports.map((airport, airportIndex) => {
              const nextAirport = trip.airports[airportIndex + 1];
              return (
                <Fragment key={airport.code}>
                  {nextAirport ? (
                    <Fragment>
                      <div className="refresh-booking-flight_info__steps-step">
                        <img
                          className="refresh-booking-flight_info__steps-step__flag"
                          src={airport.countryFlag}
                          alt={airport.code}
                        />

                        <div className="refresh-booking-flight_info__steps-step__code--primary">
                          {airport.code}
                        </div>
                        <Icon size="tiny" name="arrowForwardAlt" />
                      </div>
                      <div className="refresh-booking-flight_info__steps-step">
                        <img
                          className="refresh-booking-flight_info__steps-step__flag"
                          src={nextAirport.countryFlag}
                          alt={airport.code}
                        />
                        <div className="refresh-booking-flight_info__steps-step__code">
                          {nextAirport.code}
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </Fragment>
              );
            })}
          </div>
          <div className="refresh-booking-flight_info__destinations">
            {I18n.t('components.ibe.flight_service_selection.service_header_text', {
              originPlaceName: currentOrigin.placeName,
              originCountryName: currentOrigin.countryName,
              destinationPlaceName: currentDestination.placeName,
              destinationCountryName: currentDestination.countryName,
            })}
          </div>
        </Fragment>
      );
    });

    return (
      <div className="refresh-booking__price-breakdown-container col-12">
        <div className="refresh-booking__before col-6 col-bleed">
          <div className="refresh-booking__before--tag">
            {I18n.t('admin.components.refresh_booking.before')}
          </div>
          <div className="refresh-booking-flight_info col-12 col-bleed">{journeyDetail}</div>
          <div className="refresh-booking__border refresh-booking__border--old">
            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.net_price')}
              currency={values.before.currency}
              value={values.before.netPrice}
            />

            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.margin_price')}
              currency={values.before.currency}
              value={values.before.margin}
            />

            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.gross_price')}
              currency={values.before.currency}
              value={values.before.grossPrice}
            />

            <div className="refresh-booking__action refresh-booking__action--old col-12 col-bleed">
              <div className="refresh-booking__modifier" />
              <Button
                version="v2"
                primary={true}
                label={I18n.t('admin.components.refresh_booking.confirm.old_price')}
                onClick={() => this.decide(true)}
                disabled={disabled}
                size="small"
              />
            </div>
          </div>
        </div>
        <div className="refresh-booking__after col-6 col-bleed">
          <div className="refresh-booking__after--tag">
            {I18n.t('admin.components.refresh_booking.after')}
          </div>
          <div className="refresh-booking-flight_info col-12 col-bleed">{journeyDetail}</div>
          <div className="refresh-booking__border refresh-booking__border--new">
            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.net_price')}
              currency={values.after.currency}
              value={values.after.netPrice}
            />

            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.margin_price')}
              currency={values.after.currency}
              value={values.after[this.state.style].margin}
            />

            <PriceLabel
              label={I18n.t('activerecord.attributes.order_item.gross_price')}
              currency={values.after.currency}
              value={values.after[this.state.style].grossPrice}
            />

            <div className="refresh-booking__action col-12 col-bleed">
              <div className="refresh-booking__modifier col-12 col-bleed">
                <CheckBox
                  label={I18n.t('admin.components.refresh_booking.confirm.apply_old_margin')}
                  size="small"
                  name="apply-old-margin"
                  checked={this.state.style === 'withOldMargin'}
                  onChange={this.toggleStyle}
                />
              </div>
              <Button
                version="v2"
                primary={true}
                label={I18n.t('admin.components.refresh_booking.confirm.new_price')}
                disabled={this.state.processing}
                size="small"
                onClick={() => this.decide(false)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmAttributeChanges.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onDecision: PropTypes.func.isRequired,
  values: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    before: PropTypes.shape({
      grossPrice: PropTypes.string.isRequired,
      netPrice: PropTypes.string.isRequired,
      margin: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
    }).isRequired,
    after: PropTypes.shape({
      netPrice: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      withNewMargin: PropTypes.shape({
        grossPrice: PropTypes.string.isRequired,
        margin: PropTypes.string.isRequired,
      }).isRequired,
      withOldMargin: PropTypes.shape({
        grossPrice: PropTypes.string.isRequired,
        margin: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  trips: PropTypes.arrayOf(TripShape).isRequired,
};

export default hot(module)(ConfirmAttributeChanges);
