import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Amount from 'sharedWebpack/Amount';
import getTripType from '../../../../IBE/lib/helpers/getTripType';
import getDestinationByTripType from '../../../../IBE/lib/helpers/getDestinationByTripType';
import JourneySummaryIndicator from '../../../../JourneySummaryIndicator';
import './styles.scss';

const RouteInfoWithPrice = ({ trips, currency, grossAmount, supplierReservationIdentifier }) => {
  const tripType = getTripType(trips);
  const originCode = trips[0]?.originCode;
  const destinations = trips.map(({ destinationCode }) => destinationCode);
  const destinationCode = getDestinationByTripType(tripType, destinations);
  const allAirports = trips.flatMap(({ airports }) => airports);
  const originCountryInfo = allAirports.find(airport => airport.code === originCode);
  const destinationCountryInfo = allAirports.find(airport => airport.code === destinationCode);

  const tripTypeMapper = {
    one_way: I18n.t('components.ibe.results.one_way'),
    round_trip: I18n.t('components.ibe.results.round_trip'),
    multi_city: I18n.t('components.ibe.results.multi_city'),
  };

  return (
    <div className="d-flex align-center justify-between route-info-with-price">
      <div className="d-flex align-center column-gap-12">
        <JourneySummaryIndicator
          tripType={tripType}
          originCountryInfo={{
            code: originCountryInfo?.code,
            flag: originCountryInfo?.countryFlag,
            name: originCountryInfo?.countryName,
          }}
          destinationCountryInfo={{
            code: destinationCountryInfo?.code,
            flag: destinationCountryInfo?.countryFlag,
            name: destinationCountryInfo?.countryName,
          }}
        />
        <EmphasisTag text={tripTypeMapper[tripType]} size="tiny" type="neutral" />
        {supplierReservationIdentifier && (
          <span className="route-info-with-price__identifier">
            {I18n.t(
              'admin.components.orders.items_tab.placeholder.reservation_id.airline_reservation_id',
              {
                id: supplierReservationIdentifier,
              },
            )}
          </span>
        )}
      </div>
      <Amount className="route-info-with-price__amount" currency={currency} value={grossAmount} />
    </div>
  );
};

RouteInfoWithPrice.defaultProps = {
  supplierReservationIdentifier: '',
};

RouteInfoWithPrice.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({ originCode: PropTypes.string, destinationCode: PropTypes.string }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  grossAmount: PropTypes.string.isRequired,
  supplierReservationIdentifier: PropTypes.string,
};

export default RouteInfoWithPrice;
