import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from '@wtag/rcl-icon';
import { ONEWAY, ROUNDTRIP } from '../../../../../../shared/IBE/lib/helpers/tripTypes';
import './journey-indicator.styles.scss';

const JourneyIndicator = ({ originDetails, destinationDetails, tripType }) => {
  const {
    code: originCode,
    countryFlag: originCountryFlag,
    countryName: originCountryName,
  } = originDetails;

  return (
    <div className="journey-indicator">
      <div className="journey-indicator__origin">
        <img src={originCountryFlag} alt={originCountryName} />
        <span className="journey-indicator__code">{originCode}</span>
      </div>

      {destinationDetails.map(destination => {
        const {
          code: destinationCode,
          countryFlag: destinationCountryFlag,
          countryName: destinationCountryName,
        } = destination;

        return (
          <Fragment key={destinationCode}>
            <div className="journey-indicator__arrow-icon">
              <Icon name="arrowUp" rotate="90" size="tiny" />
              {tripType === ROUNDTRIP && <Icon name="arrowDown" rotate="90" size="tiny" />}
            </div>
            <div className="journey-indicator__destination">
              <img src={destinationCountryFlag} alt={destinationCountryName} />
              <span className="journey-indicator__code">{destinationCode}</span>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

JourneyIndicator.defaultProps = {
  tripType: ONEWAY,
};

JourneyIndicator.propTypes = {
  originDetails: PropTypes.shape({
    code: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
    countryFlag: PropTypes.string.isRequired,
  }).isRequired,
  destinationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      countryName: PropTypes.string,
      countryFlag: PropTypes.string,
    }),
  ).isRequired,
  tripType: PropTypes.string,
};

export default JourneyIndicator;
