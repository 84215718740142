import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem } from '@wtag/rcl-accordion';
import { EmphasisTag } from '@wtag/react-comp-lib';
import JourneyIndicator from '../../Indicators/JourneyIndicator';
import EmptyServicePlaceholder from '../EmptyServicePlaceholder';
import Service from '../Service';
import SegmentServicesWrapper from './SegmentServiceWrapper';
import { SUCCESS, DISABLED } from '../../../../../lib/helpers/componentStates';
import getTripServices from '../../../../helpers/getTripServices';
import './styles.scss';

const TripServices = ({ trips, services, airportDetails, onChange, travelers }) => {
  const [activeAccordionId, setActiveAccordionId] = useState('');

  const onAccordionClick = id => {
    setActiveAccordionId(activeAccordionId === id ? '' : id);
  };

  const getSegmentServicesCount = segments => {
    let serviceCount = 0;

    services.forEach(service => {
      segments.forEach(segment => {
        if (
          service.segment &&
          service.segment.origin === segment.originCode &&
          service.segment.destination === segment.destinationCode
        ) {
          serviceCount += 1;
        }
      });
    });

    return serviceCount;
  };

  return (
    <div className="trip-services">
      <div className="trip-services__title">
        {I18n.t('components.ibe.flight_service_selection.trip_services')}
      </div>
      <Accordion>
        {trips.map(trip => {
          const { originCode, destinationCode } = trip;
          const tripServices = getTripServices(services, trip.originCode, trip.destinationCode);
          const segmentCount = trip.segments.length;
          const serviceCount = tripServices.length;
          const segmentServiceCount = getSegmentServicesCount([...trip.segments]);
          const originDetails = {
            code: originCode,
            countryFlag: airportDetails[originCode].countryFlag,
            countryName: airportDetails[originCode].countryName,
            placeName: airportDetails[originCode].placeName,
          };
          const destinationDetails = [
            {
              code: destinationCode,
              countryFlag: airportDetails[destinationCode].countryFlag,
              countryName: airportDetails[destinationCode].countryName,
              placeName: airportDetails[destinationCode].placeName,
            },
          ];

          const getSegmentServicesCountText = () => {
            if (segmentServiceCount > 0) {
              return ` ${I18n.t('components.ibe.flight_service_selection.segment_service_count', {
                count: segmentServiceCount,
              })} ${I18n.t('components.ibe.flight_service_selection.segment_count', {
                count: segmentCount,
              })}`;
            }

            return I18n.t('components.ibe.flight_service_selection.segment_service_count', {
              count: segmentServiceCount,
            });
          };

          return (
            <AccordionItem
              key={trip.id}
              id={trip.id}
              isActive={trip.id === activeAccordionId}
              onClick={onAccordionClick}
              header={
                <div className="trip-services__header">
                  <div className="trip-services__header--left">
                    <JourneyIndicator
                      originDetails={originDetails}
                      destinationDetails={destinationDetails}
                    />
                    <div className="trip-services__header--text">
                      {I18n.t('components.ibe.flight_service_selection.service_header_text', {
                        originPlaceName: originDetails.placeName,
                        originCountryName: originDetails.countryName,
                        destinationPlaceName: destinationDetails[0].placeName,
                        destinationCountryName: destinationDetails[0].countryName,
                      })}
                    </div>
                  </div>
                  <div className="trip-services__header--right">
                    <EmphasisTag
                      text={I18n.t('components.ibe.flight_service_selection.trip_services_count', {
                        count: serviceCount,
                      })}
                      type={serviceCount > 0 ? SUCCESS : DISABLED}
                      size="tiny"
                    />
                    <EmphasisTag
                      text={getSegmentServicesCountText()}
                      type={segmentServiceCount > 0 ? SUCCESS : DISABLED}
                      size="tiny"
                    />
                  </div>
                </div>
              }
            >
              <div className="trip-services__subheader">
                {I18n.t('components.ibe.flight_service_selection.available_trip_services')}
              </div>
              <div className="trip-services__container">
                {serviceCount === 0 ? (
                  <EmptyServicePlaceholder
                    text={I18n.t('components.ibe.flight_service_selection.trip_services_count', {
                      count: serviceCount,
                    })}
                  />
                ) : (
                  <div className="trip-services__content">
                    {tripServices.map(service => (
                      <Service
                        key={service.id}
                        onChange={(quantity, price, travelerIndices) =>
                          onChange(service.id, quantity, price, travelerIndices)
                        }
                        travelers={travelers}
                        service={service}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="trip-services__segment-title">
                {I18n.t('components.ibe.flight_service_selection.available_segment_services')}
              </div>
              <div className="segment-services__container">
                <SegmentServicesWrapper
                  services={services}
                  segments={trip.segments}
                  airportDetails={airportDetails}
                  onChange={onChange}
                  travelers={travelers}
                />
              </div>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

TripServices.propTypes = {
  trips: PropTypes.arrayOf(
    PropTypes.shape({
      originCode: PropTypes.string,
      destinationCode: PropTypes.string,
      airports: PropTypes.arrayOf(
        PropTypes.shape({ countryFlag: PropTypes.string, countryName: PropTypes.string }),
      ),
    }),
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      trip: PropTypes.shape({
        origin: PropTypes.string,
        destination: PropTypes.string,
      }),
    }),
  ).isRequired,
  airportDetails: PropTypes.shape({
    code: PropTypes.string,
    countryName: PropTypes.string,
    countryFlag: PropTypes.string,
  }).isRequired,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      name: PropTypes.node.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TripServices;
