import React from 'react';
import PropTypes from 'prop-types';
import JourneyTripSummaryIndicator from '../Cart/Items/flight/Indicators/JourneyTripSummaryIndicator';
import getTripOriginDetails from '../helpers/getTripOriginDetails';
import getTripType from '../lib/helpers/getTripType';
import { ROUNDTRIP } from '../lib/helpers/tripTypes';

const TripSummaryContent = ({ className, trips, airports }) => {
  const originDetails = getTripOriginDetails(trips, airports);
  const destinationDetailsList = trips.map(
    ({ destination, destinationCode }) => airports[destinationCode] || airports[destination],
  );

  const tripType = getTripType(trips);
  const isRoundTrip = tripType === ROUNDTRIP;

  return (
    <JourneyTripSummaryIndicator
      className={className}
      originDetails={originDetails}
      destinationDetailsList={destinationDetailsList}
      isRoundTrip={isRoundTrip}
      tripType={getTripType(trips)}
    />
  );
};

const AirportsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const TripShape = PropTypes.shape({
  origin: PropTypes.string,
  destination: PropTypes.string,
});

TripSummaryContent.defaultProps = {
  className: null,
  trips: [],
};

TripSummaryContent.propTypes = {
  className: PropTypes.string,
  airports: AirportsShape.isRequired,
  trips: PropTypes.arrayOf(TripShape),
};

export default TripSummaryContent;
