import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@wtag/rcl-popover';
import Icon from '@wtag/rcl-icon';
import { I18nText } from '@wtag/react-comp-lib';
import '../../styles.scss';
import JourneyIndicator from '../Indicators/JourneyIndicator';

const FlightInfoSummaryPopover = props => {
  const { originDetails, destinationDetails } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const popoverFlightInformationContent = () => {
    if (originDetails && destinationDetails) {
      const { countryName: originCountryName, placeName: originPlaceName } = originDetails;
      const {
        countryName: destinationCountryName,
        placeName: destinationPlaceName,
      } = destinationDetails;

      return (
        <div className="popover-flight-info">
          <JourneyIndicator
            originDetails={originDetails}
            destinationDetails={[{ ...destinationDetails }]}
          />
          <div className="popover-flight-info__place-summary">
            {originPlaceName}, {originCountryName} {I18n.t('components.ibe.flight.to')}{' '}
            {destinationPlaceName}, {destinationCountryName}
          </div>
        </div>
      );
    }

    return (
      <div className="popover-flight-info__no-information">
        <I18nText id="ibe.flight.no_information" returnStringOnly={true} />
      </div>
    );
  };

  return (
    <Popover
      isVisible={isPopoverOpen}
      direction="bottom-left"
      size="medium"
      content={popoverFlightInformationContent()}
      showTip={true}
    >
      <span
        className="hint-icon-wrapper"
        onMouseOver={() => setIsPopoverOpen(true)}
        onMouseLeave={() => setIsPopoverOpen(false)}
        onFocus={() => setIsPopoverOpen(true)}
      >
        <Icon name="neutralOutline" className="flight-journey-indicator__popover--hint" />
      </span>
    </Popover>
  );
};

FlightInfoSummaryPopover.defaultProps = {
  originDetails: {},
  destinationDetails: {},
};

FlightInfoSummaryPopover.propTypes = {
  originDetails: PropTypes.shape({
    countryFlag: PropTypes.string,
    countryName: PropTypes.string,
    placeName: PropTypes.string,
  }),
  destinationDetails: PropTypes.shape({
    countryFlag: PropTypes.string,
    countryName: PropTypes.string,
    placeName: PropTypes.string,
  }),
};

export default FlightInfoSummaryPopover;
