import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import TripSummaryContent from '../TripSummaryContent';
import getTripType from '../lib/helpers/getTripType';
import './styles.scss';

const TripSummaryDetails = ({ className, airports, trips, text }) => (
  <div className="d-flex-col trip-summary-details">
    <div className="d-flex justify-between trip-summary-details__content">
      <TripSummaryContent className={className} trips={trips} airports={airports} />
      <EmphasisTag
        text={I18n.t(getTripType(trips), {
          scope: 'components.ibe.results',
        })}
        type="neutral"
        size="small"
      />
    </div>
    {text && <span className="trip-summary-details__text">{text}</span>}
  </div>
);

const AirportsShape = PropTypes.shape({
  [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const TripShape = PropTypes.shape({
  origin: PropTypes.string,
  destination: PropTypes.string,
});

TripSummaryDetails.defaultProps = {
  className: null,
  text: null,
};

TripSummaryDetails.propTypes = {
  className: PropTypes.string,
  airports: AirportsShape.isRequired,
  trips: PropTypes.arrayOf(TripShape).isRequired,
  text: PropTypes.node,
};

export default TripSummaryDetails;
