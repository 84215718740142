import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { travelerInitialValueMapper, getOperatingCarriers } from '../lib/helpers/mapCustomerData';
import {
  saveTravelerDetails,
  customerSelected,
  fetchCurrentCustomer,
  resetTravelerDetails,
  resetTravelersError,
  updateTravelerSavingStatus,
  updateProceedToPaymentPageView,
  proceedToPayment,
  getStoredCustomerData,
  setResoOptionEmail,
  setResoOptionPhone,
  saveApprovalRequest,
  fetchCart,
} from '../actions/common';

import { validate, presenceValidation, phoneNumberValidation } from '../lib/validations';

import asyncPhoneNumberValidate from '../lib/asyncValidations';
import asyncTravelerEmailValidate from '../lib/asyncTravelerEmailValidations';

import {
  validateTravelerAddress,
  validateEmailPresenceForLayman,
  validateBirthday,
  validateDuplicateEmail,
  phoneNumberCommonValidation,
  validateEmailForGalileo,
  validateSalutation,
  validateTravelerFullname,
} from '../lib/validations/commonBookingValidations';

import {
  validateFreqeuntFlyerNumber,
  validateIdentificationDocument,
  validateDuplicateName,
} from '../lib/validations/flightValidations';

import validateEmailForHotel from '../lib/validations/hotelValidations';

import TravelerDetails from './TravelerDetails';

import cartNeedsApproval from '../lib/cartNeedsApproval';

const constraints = {
  namePrefix: { ...presenceValidation },
  phone: { ...phoneNumberValidation, ...phoneNumberCommonValidation },
};

const validateFields = (
  data,
  operatingCarriers,
  itemChannels,
  laymanMode,
  internationalFlight,
  enableIdDocOptional,
  identificationDocumentsRequired,
  isTravelerLoggedIn,
) => {
  let errors;
  const travelers = data.travelers.map(values => {
    if (values) {
      const formValues = values;
      formValues.cartHasFlight = data.cartHasFlight;
      return {
        ...validate(formValues, constraints),
        ...validateBirthday(formValues, itemChannels),
        ...validateEmailForHotel(formValues, itemChannels),
        ...validateEmailForGalileo(formValues, itemChannels),
        ...validateDuplicateName(formValues, data.travelers, operatingCarriers, itemChannels),
        ...validateDuplicateEmail(formValues, data.travelers),
        ...validateEmailPresenceForLayman(formValues, laymanMode, isTravelerLoggedIn),
        ...validateSalutation(formValues),
        ...validateTravelerFullname(formValues),
        residenceAddress: validateTravelerAddress(formValues, laymanMode, itemChannels),
        frequentFlyerNumber: validateFreqeuntFlyerNumber(formValues),
        document: validateIdentificationDocument(
          formValues,
          itemChannels,
          laymanMode,
          internationalFlight,
          enableIdDocOptional,
          identificationDocumentsRequired,
        ),
      };
    }
    return values;
  });

  if (travelers.length > 0) {
    errors = { travelers };
  }
  return errors;
};

const validateAllFields = (
  operatingCarriers,
  itemChannels,
  laymanMode,
  internationalFlight,
  enableIdDocOptional,
  identificationDocumentsRequired,
  isTravelerLoggedIn,
) => params => {
  const errors = validateFields(
    params,
    operatingCarriers,
    itemChannels,
    laymanMode,
    internationalFlight,
    enableIdDocOptional,
    identificationDocumentsRequired,
    isTravelerLoggedIn,
  );
  return errors;
};

const formConfig = {
  form: 'multiTraveler',
  fields: [
    'travelers[].id',
    'travelers[].newUser',
    'travelers[].guestUser',
    'travelers[].associateOrganization',
    'travelers[].contactInformationCopied',
    'travelers[].type',
    'travelers[].childAge',
    'travelers[].driverAge',
    'travelers[].namePrefix',
    'travelers[].firstName',
    'travelers[].middleName',
    'travelers[].lastName',
    'travelers[].birthday',
    'travelers[].email',
    'travelers[].travelArrangerEmail',
    'travelers[].isTravelArrangerEmailUsed',
    'travelers[].phone',
    'travelers[].assistanceRequests',
    'travelers[].mealRequests',
    'travelers[].splitPrimaryPhoneNumber.countryCode',
    'travelers[].splitPrimaryPhoneNumber.number',
    'travelers[].frequentFlyerNumber.carrier',
    'travelers[].frequentFlyerNumber.number',
    'travelers[].document.id',
    'travelers[].document.type',
    'travelers[].document.number',
    'travelers[].document.country',
    'travelers[].document.nationality',
    'travelers[].document.validity',
    'travelers[].destinationAddress.name',
    'travelers[].destinationAddress.street',
    'travelers[].destinationAddress.city',
    'travelers[].destinationAddress.zip',
    'travelers[].destinationAddress.country',
    'travelers[].residenceAddress.street',
    'travelers[].residenceAddress.city',
    'travelers[].residenceAddress.state',
    'travelers[].residenceAddress.zip',
    'travelers[].residenceAddress.country',
    'travelers[].adjustProfileData',
    'cartHasFlight',
  ],
  destroyOnUnmount: false,
};

const mapStateToProps = (state, ownProps) => {
  const { initialValues, currentValues, customers, firstTravelerInfo } = travelerInitialValueMapper(
    state,
    ownProps,
  );
  const operatingCarriers = getOperatingCarriers(state.common);
  const itemChannels = state.common.cart.items.map(item => item.channel);
  const internationalFlight = state.common.cart.validations.internationalFlight;
  const cartHasFlight = Object.keys(state.common.journeyElements.flight).length > 0;
  const mealRequestsByResultId = {};
  const assistanceRequestsByResultId = {};
  const isCarAdded = state.cars.addCar.isCarAdded;
  const {
    isCartFetching,
    isSavingTravelerDetails,
    flightServicesFetched,
    isFetchingVisaAdvisory,
    proceedToPaymentPageView,
  } = state.common.cart;

  const { flight } = state.common.journeyElements;
  const hasFlightItems = Object.keys(flight).length > 0;

  const isTravelerInformationLoading = isCartFetching || (hasFlightItems && !flightServicesFetched);

  let identificationDocumentsRequired = false;

  if (cartHasFlight) {
    Object.keys(state.flights.servicesByResultId).forEach(key => {
      const mealRequests = state.flights.servicesByResultId[key].mealRequests;
      const identificationDocuments = state.flights.servicesByResultId[key].identificationDocuments;
      const assistanceRequests = state.flights.servicesByResultId[key].assistanceRequests;

      if (identificationDocuments && identificationDocuments.required) {
        identificationDocumentsRequired = true;
      }

      if (typeof mealRequests !== 'undefined') {
        mealRequestsByResultId[key] = mealRequests;
      }

      if (typeof assistanceRequests !== 'undefined') {
        assistanceRequestsByResultId[key] = assistanceRequests;
      }
    });
  }
  return {
    initialValues,
    customers,
    internationalFlight,
    formValues: currentValues,
    totalTravelerCount: initialValues.travelers.length,
    selectedCustomers: state.common.selectedCustomers,
    firstTravelerInfo,
    validate: validateAllFields(
      operatingCarriers,
      itemChannels,
      ownProps.laymanMode,
      internationalFlight,
      ownProps.enableIdDocOptional,
      identificationDocumentsRequired,
      ownProps.travelerLoggedIn,
    ),
    currentCustomer: state.common.currentCustomer,
    travelersError: state.common.cart.travelersError,
    passEmailToAirline: state.common.cart.resoOptions.passEmailToAirline,
    passPhoneToAirline: state.common.cart.resoOptions.passPhoneToAirline,
    mealRequests: mealRequestsByResultId,
    assistanceRequests: assistanceRequestsByResultId,
    forceApprovalRequest: cartNeedsApproval(state),
    cartId: state.common.cart.id,
    identificationDocumentsRequired,
    operatingCarriers,
    isCarAdded,
    isTravelerInformationLoading,
    isFetchingVisaAdvisory,
    isSavingTravelerDetails,
    proceedToPaymentPageView,
    travelerUpdateAbilities: state.common.cart.travelerUpdateAbilities,
  };
};

const mapDispatchToProps = dispatch => ({
  setResoOptionEmail: value => dispatch(setResoOptionEmail(value)),
  setResoOptionPhone: value => dispatch(setResoOptionPhone(value)),
  fetchCurrentCustomer: callbackParams => dispatch(fetchCurrentCustomer(callbackParams)),
  fetchCartPeople: (cartId, callbackParams) =>
    dispatch(getStoredCustomerData(cartId, callbackParams)),
  saveTravelers: (formData, callbackParams, readOnlyTravelArrangerWithUnchangedData) => {
    const travelerPromises = formData.travelers.map((data, index) =>
      dispatch(
        saveTravelerDetails(index, data, callbackParams, readOnlyTravelArrangerWithUnchangedData),
      ),
    );
    return Promise.all(travelerPromises);
  },
  resetForGuest: index => {
    dispatch(resetTravelerDetails(index));
  },
  customerSelected: (index, query) => dispatch(customerSelected(index, query)),
  resetTraveler: index => dispatch(resetTravelerDetails(index)),
  resetTravelersError: () => dispatch(resetTravelersError()),
  updateProceedToPaymentPageView: proceedToPaymentPageView =>
    dispatch(updateProceedToPaymentPageView(proceedToPaymentPageView)),
  proceedToPayment: () => dispatch(proceedToPayment()),
  updateTravelerSavingStatus: params => dispatch(updateTravelerSavingStatus(params)),
  asyncTravelerEmailValidate: formValues =>
    asyncTravelerEmailValidate(formValues, 'multiTraveler', dispatch),
  asyncPhoneNumberValidate: formValues =>
    asyncPhoneNumberValidate(formValues, 'multiTraveler', dispatch),
  saveApprovalRequest: (callbackParams, optionalApproverIds = []) =>
    dispatch(saveApprovalRequest(callbackParams, optionalApproverIds)),
  fetchCart: (cartId, callbackParams) => dispatch(fetchCart(cartId, callbackParams)),
});

const ReduxForm = reduxForm(formConfig, mapStateToProps, mapDispatchToProps)(TravelerDetails);
export default injectIntl(ReduxForm);
