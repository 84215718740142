import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import { TYPE_SEAT } from 'sharedWebpack/helpers/itemTypes';
import { WARNING, SUCCESS } from 'sharedWebpack/IBE/lib/helpers/componentStates';

const SeatTags = ({ seatCount, segments, seatAmountsAndTitles }) => {
  const getSeatDetails = (row, seat, route) => {
    const seatTitle = `${row}${seat}`;
    const desiredSeat = seatAmountsAndTitles.find(({ itemTitle }) => seatTitle === itemTitle);
    const { grossAmount } = desiredSeat || {};
    const status = Number(grossAmount) > 0 ? WARNING : SUCCESS;
    const seatTitleWithRoute = `${seatTitle} ${route}`;

    return { status, seatTitleWithRoute };
  };

  const getEmphasisTagList = (seats, route) =>
    seats.map(({ row, seat }) => {
      const { status, seatTitleWithRoute } = getSeatDetails(row, seat, route);

      return (
        <EmphasisTag
          className="seat-tag"
          key={seatTitleWithRoute}
          text={
            <Icon
              className="seat-tag__icon"
              name={TYPE_SEAT}
              size="tiny"
              color={status}
              showLabel={true}
              iconLabel={seatTitleWithRoute}
            />
          }
          type={status}
          size="tiny"
        />
      );
    });

  const allSeatsWithEmphasisTag = segments.flatMap(({ originCode, destinationCode, seats }) => {
    const route = `(${originCode} - ${destinationCode})`;
    return getEmphasisTagList(seats, route);
  });

  return allSeatsWithEmphasisTag.slice(0, seatCount);
};

SeatTags.propTypes = {
  seatAmountsAndTitles: PropTypes.arrayOf(
    PropTypes.shape({
      grossAmount: PropTypes.string,
      itemTitle: PropTypes.string,
    }),
  ).isRequired,
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      originCode: PropTypes.string,
      destinationCode: PropTypes.string,
      seats: PropTypes.arrayOf(
        PropTypes.shape({
          row: PropTypes.string,
          seat: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default SeatTags;
