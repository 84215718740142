import React from 'react';
import PropTypes from 'prop-types';
import { EmphasisTag } from '@wtag/react-comp-lib';
import Icon from '@wtag/rcl-icon';
import { BAGGAGE_ICON } from 'sharedWebpack/ProductIcon/productIconNames';

const ServiceItemTag = ({ label }) => (
  <EmphasisTag
    className="seat-tag"
    text={
      <Icon
        name={BAGGAGE_ICON}
        className="seat-tag__icon"
        size="tiny"
        color="warning"
        showLabel={true}
        iconLabel={label}
      />
    }
    type="warning"
    size="tiny"
  />
);

ServiceItemTag.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ServiceItemTag;
