function getTripOriginDetails(trips, airports) {
  const hasTrips = Array.isArray(trips) && trips.length > 0;

  if (!hasTrips || !airports) {
    return null;
  }

  const originCode = trips[0].originCode || trips[0].origin;

  return airports[originCode];
}

export default getTripOriginDetails;
