import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '@wtag/rcl-icon';
import { MULTICITY, ROUNDTRIP } from 'sharedWebpack/IBE/lib/helpers/tripTypes';
import './styles.scss';

const ROTATION_90 = '90';

const JourneyIndicatorRow = ({ className, tripOriginDetails, children }) => (
  <div className={classNames('journey-indicator', className)}>
    {tripOriginDetails}
    {children}
  </div>
);

JourneyIndicatorRow.defaultProps = {
  className: null,
};

JourneyIndicatorRow.propTypes = {
  className: PropTypes.string,
  tripOriginDetails: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

const JourneyIndicatorIcons = ({ icons, rotation, size }) => (
  <div className="d-flex-col align-center journey-indicator__arrow-icon">
    {icons &&
      icons.map(iconName => <Icon key={iconName} name={iconName} rotate={rotation} size={size} />)}
  </div>
);

JourneyIndicatorIcons.defaultProps = {
  icons: [],
  rotation: null,
  size: 'tiny',
};

JourneyIndicatorIcons.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
  rotation: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.instanceOf(null), PropTypes.string]),
};

const TripDetails = ({ tripDetails }) => {
  if (!tripDetails) {
    return null;
  }

  const { countryFlag, countryName, code } = tripDetails;

  return (
    <div className="d-flex justify-between align-center journey-indicator__content">
      <img
        className="journey-indicator__image"
        src={countryFlag}
        alt={countryName}
        loading="lazy"
      />
      {code && <span className="journey-indicator__airport-code">{code}</span>}
    </div>
  );
};

const TripDetailsShape = PropTypes.shape({
  countryFlag: PropTypes.string,
  countryName: PropTypes.string,
  code: PropTypes.string,
});

TripDetails.propTypes = {
  tripDetails: TripDetailsShape.isRequired,
};

const JourneyTripSummaryIndicator = ({
  className,
  originDetails,
  destinationDetailsList,
  tripType,
}) => {
  const tripOriginDetails = <TripDetails tripDetails={originDetails} />;

  const getIconsListAndDestinationDetails = currentTripType => {
    const defaultDestinationDetails = destinationDetailsList[destinationDetailsList.length - 1];

    switch (currentTripType) {
      case ROUNDTRIP:
        return {
          iconRotation: ROTATION_90,
          iconsList: ['arrowUp', 'arrowDown'],
          destinationDetails: destinationDetailsList[0],
        };

      case MULTICITY:
        return {
          iconSize: 'small',
          iconsList: ['multiCity'],
          destinationDetails: defaultDestinationDetails,
        };

      default:
        return {
          iconRotation: ROTATION_90,
          iconsList: ['arrowUp'],
          destinationDetails: defaultDestinationDetails,
        };
    }
  };

  const {
    iconSize,
    iconRotation,
    iconsList,
    destinationDetails,
  } = getIconsListAndDestinationDetails(tripType);

  return (
    <JourneyIndicatorRow className={className} tripOriginDetails={tripOriginDetails}>
      <JourneyIndicatorIcons rotation={iconRotation} icons={iconsList} size={iconSize} />
      <TripDetails tripDetails={destinationDetails} />
    </JourneyIndicatorRow>
  );
};

JourneyTripSummaryIndicator.defaultProps = {
  className: null,
};

JourneyTripSummaryIndicator.propTypes = {
  className: PropTypes.string,
  originDetails: TripDetailsShape.isRequired,
  destinationDetailsList: PropTypes.arrayOf(TripDetailsShape).isRequired,
  tripType: PropTypes.string.isRequired,
};

export default JourneyTripSummaryIndicator;
