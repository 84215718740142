import React from 'react';
import Popover from '@wtag/rcl-popover';
import PropTypes from 'prop-types';

const PopoverItems = ({ isVisible, content, onOutsideClick, hiddenElements }) => (
  <Popover
    className="tags-popover"
    isVisible={isVisible}
    direction="bottom"
    size="medium"
    content={content}
    onOutsideClick={onOutsideClick}
  >
    {hiddenElements}
  </Popover>
);

PopoverItems.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  hiddenElements: PropTypes.node.isRequired,
};

export default PopoverItems;
